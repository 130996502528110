import React, {useEffect, useState} from "react";
import Column from "@amzn/meridian/column";
import InProgress from "../components/common/InProgress";
import {ScanWithCodeView} from "@amzn/dolphin-uiweb-framework";
import qr_code from "../assets/images/ic_qr_code.png";
import Printer from "./Printer";
import {FormattedMessage, injectIntl} from "react-intl";
import Alert from "@amzn/meridian/alert";
import PropTypes from 'prop-types';
import {Logger} from "@amzn/dolphin-web-framework";
import {SoundManager} from "../audio/SoundManager";
import {
    savePrinterMACAddress
} from "../dataStore/PSExecutionDataStore";
const ConnectPrinter = ({intl, history}) => {
    const [isLoaderVisible, setLoaderVisible] = useState(false);
    const [isError, setError] = useState(false);
    const onPrinterScan = async (text) => {
        Logger.log.info("Scanned value in PrinterConnection - " + text);
        try {
            setLoaderVisible(true);
            const response = await Printer.connectPrinter(text);
             if(response === true) {
                   Logger.log.info("Printer connected - " + text);
                   savePrinterMACAddress(text)
                   history.goBack();
              } else {
                  setError(true);
              }
        } catch (e) {
             setError(true)
        } finally {
            setLoaderVisible(false);
        }
    };
    const init = {
        primaryTitle: {
            title: intl.formatMessage({id: "connect_printer_to_print_labels"})
        },
        secondaryTitle: {
            title: intl.formatMessage({id: "scan_printer"}),
            size: "Large",
            type: "Bold"
        },
        image: qr_code,
        onSubmit: onPrinterScan,
        focusLock: true
    };

    useEffect(() => {
        SoundManager.playWarningSound();
    }, []);

    return (<Column
        height="100%"
        spacing="small"
        spacingInset="small">
        {isLoaderVisible && <InProgress/>}
        {isError &&
        <Alert type="error" size="medium" onClose={() => setError(false)}>
            <FormattedMessage id="no_printer_connected"/>
        </Alert>}
        <ScanWithCodeView {...init}/>
    </Column>);
};
ConnectPrinter.propTypes = {
    intl: PropTypes.object,
    history: PropTypes.object
};
export default injectIntl(ConnectPrinter);