import React from 'react';
import {withRouter} from 'react-router-dom';
import Box from "@amzn/meridian/box";
import Select, {SelectOption} from "@amzn/meridian/select";
import {FormattedMessage, injectIntl} from "react-intl";
import Text from "@amzn/meridian/text";
import {getDisplayName} from "../../handler/TranslationHandler";
import {ROUTE_PATH} from "../../router/routing";
import {ENTITY_TYPES} from "../../constants/Constants";
import PropTypes from 'prop-types';
import TopNavigation from "../common/TopNavigation";
import {ROUTE_ENTITY_ROUTE_PATH} from "../../router/RouteEntityRouter";
import {PROBLEM_SOLVE_EXECUTION_ROUTE_PATHS} from "../../router/ProblemSolveExecutionRouter";
import {DRIVER_ENTITY_ROUTE_PATH} from "../../router/DriverEntityRouter";
import AppContextProvider from "../backPressedView/AppContext";
import {saveDriverEntityState} from "../../dataStore/LocationStateDataStore";

const SelectEntity = ({history, intl}) => {
    const handleChangeEntity = (entity) => {
        switch (entity) {
            case ENTITY_TYPES.ROUTE:
                history.push(ROUTE_ENTITY_ROUTE_PATH.UNASSIGN_ROUTE);
                break;
            case ENTITY_TYPES.DRIVER:
                const state = {
                    entity: ENTITY_TYPES.DRIVER,
                    nextScreen: DRIVER_ENTITY_ROUTE_PATH.DRIVER_ACTION
                };
                history.push({
                    pathname: PROBLEM_SOLVE_EXECUTION_ROUTE_PATHS.COMPONENT_SELECT_DRIVER,
                    state: state
                });
                saveDriverEntityState(state);
                break;
            default:
                history.push(ROUTE_PATH.HOME);
        }
    };

    return (
        <div>
            <AppContextProvider>
                <TopNavigation title="operation_problem_solve"/>
            </AppContextProvider>
            <div className="content">
                <Box spacingInset="medium">
                    <Text type="h300"><FormattedMessage id="entity_type"/></Text>
                    <br/>
                    <div data-testid="select-entity">
                        <Select size="medium" placeholder={getDisplayName(intl, "choose_entity")}
                                onChange={(entity) => handleChangeEntity(entity)}>
                            <SelectOption label={getDisplayName(intl, "ps_entity_package")} value={ENTITY_TYPES.PACKAGE}
                                          key={ENTITY_TYPES.PACKAGE}/>
                            <SelectOption label={getDisplayName(intl, "ps_entity_route")} value={ENTITY_TYPES.ROUTE}
                                          key={ENTITY_TYPES.ROUTE}/>
                            <SelectOption label={getDisplayName(intl, "ps_entity_driver")} value={ENTITY_TYPES.DRIVER}
                                          key={ENTITY_TYPES.DRIVER}/>
                        </Select>
                    </div>
                </Box>
            </div>
        </div>
    )
};
SelectEntity.propTypes = {
    history: PropTypes.object,
    intl: PropTypes.object
};
export default withRouter(injectIntl(SelectEntity));