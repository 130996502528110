import {APIEvents} from "../../../constants/AnalyticsEventKeys";
import {MobileAnalyticsHelper} from "@amzn/dolphin-web-framework";
import {getNPSWClient} from "../../base/ClientInterface";

export function UpdateExceptionShipmentsAPI(data, metaData, exceptionScannableIdMetaData, nextAction, errorAction, dispatcher) {
    let startTime = Date.now();
    getNPSWClient().updateExceptionShipments(data, metaData, exceptionScannableIdMetaData)
    .then(({success, data}) => {
      if (success) {
          dispatcher({
            type: nextAction,
            data: data,
            metaData: metaData,
            exceptionScannableIdMetaData: exceptionScannableIdMetaData,
            dispatcher: dispatcher
          });
        MobileAnalyticsHelper.executeAPIAnalytics(APIEvents.UpdateExceptionShipments, startTime);
      } else {
        dispatcher({
          type: errorAction,
          dispatcher: dispatcher,
          data: data
        });
        MobileAnalyticsHelper.executeAPIAnalytics(APIEvents.UpdateExceptionShipments, startTime, true);
      }
    })
}