import problemCategoryConfig from "../config/ProblemCategoryConfig";
import {FeatureManager} from "@amzn/dolphin-web-framework";

export const fetchProblemCategoryConfig = () => {
  if(FeatureManager.isFeatureEnabled(FeatureManager.Features.WRONG_PLANNED_STATION_RTO_DISABLED))
  {
    problemCategoryConfig["WRONG_PLANNED_STATION"].actionList = ["WRONG_PLANNED_STATION_TRANSFER"];
  }
  if(FeatureManager.isFeatureEnabled(FeatureManager.Features.DISABLE_RTO_FOR_MISSING_PS)) {
    problemCategoryConfig["MISSING"].actionList = ["MISSING", "MISSORT_REPLAN"];
  }
  return problemCategoryConfig;
};