import {GET_STATION_LIST_ACTION_TYPE} from "../../../reducers/GetStationListReducer";
import {APIEvents} from "../../../constants/AnalyticsEventKeys";
import {MobileAnalyticsHelper} from "@amzn/dolphin-web-framework";
import {getNPSWClient} from "../../base/ClientInterface";

export function GetStationListAPI(data, dispatcher) {
    let startTime = Date.now();
    getNPSWClient().getStationList(data)
        .then(({success, data}) => {
            if (success) {
                dispatcher({
                    type: GET_STATION_LIST_ACTION_TYPE.SET_STATION_LIST,
                    data: data
                });
                MobileAnalyticsHelper.executeAPIAnalytics(APIEvents.SearchStation, startTime);
            } else {
                dispatcher({
                    type: GET_STATION_LIST_ACTION_TYPE.ERROR
                });
                MobileAnalyticsHelper.executeAPIAnalytics(APIEvents.SearchStation, startTime, true);
            }
        });
}