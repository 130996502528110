import {UpdateExceptionShipmentsAPI} from "../network/apis/npsw/UpdateExceptionShipmentsAPI";
import {getProblemCategory} from "../dataStore/PSExecutionDataStore";
import {NOTIFICATION_TYPE, TARGET_PROBLEM_STATUS} from "../constants/Constants";
import {GetPackageLabelAPI} from "../network/apis/npsw/GetPackageLabelAPI";
import {processApiExecutionResponse} from "../helper/ActionComponentsHelper";
import {useHistory} from "react-router";
import {PROBLEM_SOLVE_EXECUTION_ROUTE_PATHS} from "../router/ProblemSolveExecutionRouter";
import {FeatureManager} from "@amzn/dolphin-web-framework";
import {GetPackageLabelAPIV2} from "../network/apis/nns/GetPackageLabelAPI";

export const ReprintLabelInitialState = {
    isLoading: false,
    label: "",
    notificationType: "",
    notificationMessageId: "",
    isPrintSuccess: false
};

export const REPRINT_LABEL_REDUCERS_ACTION_TYPE = {
    EXECUTE_PROBLEM_ACTION_API: "EXECUTE_PROBLEM_ACTION_API",
    SET_PROBLEM_ACTION_API_RESULT: "SET_PROBLEM_ACTION_API_RESULT",
    CLOSE_NOTIFICATION: "CLOSE_NOTIFICATION",
    ERROR: "ERROR",
    FETCH_PACKAGE_LABEL: "FETCH_PACKAGE_LABEL",
    PRINT_SUCCESS: "PRINT_SUCCESS",
    PRINTER_ERROR: "PRINTER_ERROR",
};

export const ReprintLabelReducer = (state, action) => {
    switch (action.type) {
        case REPRINT_LABEL_REDUCERS_ACTION_TYPE.FETCH_PACKAGE_LABEL:
            const closePrinterConnection = FeatureManager.isFeatureNotEnabled(FeatureManager
                .Features.SKIP_PRINTER_DISCONNECTION);
            if (FeatureManager.isFeatureEnabled(FeatureManager.Features.IS_PACKAGE_LABEL_NNS_ENABLED)) {
                GetPackageLabelAPIV2(action.data, action.dispatcher,
                    REPRINT_LABEL_REDUCERS_ACTION_TYPE.PRINT_SUCCESS,
                    REPRINT_LABEL_REDUCERS_ACTION_TYPE.PRINTER_ERROR,
                    REPRINT_LABEL_REDUCERS_ACTION_TYPE.ERROR, closePrinterConnection);
            } else {
                GetPackageLabelAPI(action.data, action.dispatcher,
                    REPRINT_LABEL_REDUCERS_ACTION_TYPE.PRINT_SUCCESS,
                    REPRINT_LABEL_REDUCERS_ACTION_TYPE.PRINTER_ERROR,
                    REPRINT_LABEL_REDUCERS_ACTION_TYPE.ERROR, closePrinterConnection);
            }
            return {...state, isLoading: true, notificationType: "", label: ""};
        case REPRINT_LABEL_REDUCERS_ACTION_TYPE.EXECUTE_PROBLEM_ACTION_API:
            UpdateExceptionShipmentsAPI({
                    scannableIdList: [action.data.scannableId],
                    problemCategory: getProblemCategory(),
                    targetProblemStatus: TARGET_PROBLEM_STATUS.RESOLVED,
                    targetAction: "NO_OP"
                },{}, {},
                REPRINT_LABEL_REDUCERS_ACTION_TYPE.SET_PROBLEM_ACTION_API_RESULT,
                REPRINT_LABEL_REDUCERS_ACTION_TYPE.ERROR,
                action.dispatcher);
            return {
                ...state,
                isLoading: true,
                isPrintSuccess: false
            };
        case REPRINT_LABEL_REDUCERS_ACTION_TYPE.SET_PROBLEM_ACTION_API_RESULT:{
            const {exceptionResolutionResult} = processApiExecutionResponse(action.data);
            return {
                ...state,
                isLoading: false,
                exceptionResolutionResult: exceptionResolutionResult
            };
        }
        case REPRINT_LABEL_REDUCERS_ACTION_TYPE.PRINTER_ERROR:
            //eslint-disable-next-line react-hooks/rules-of-hooks
            useHistory().push(({
                pathname: PROBLEM_SOLVE_EXECUTION_ROUTE_PATHS.CONNECT_PRINTER
            }));
            return {...state, isLoading: false, notificationType: NOTIFICATION_TYPE.ALERT,
                notificationMessageId:"printer_not_connect"};
        case REPRINT_LABEL_REDUCERS_ACTION_TYPE.CLOSE_NOTIFICATION:
            return {...state, notificationType: null};
        case REPRINT_LABEL_REDUCERS_ACTION_TYPE.ERROR:
            return {...state, isLoading: false, notificationType: NOTIFICATION_TYPE.ALERT,
                notificationMessageId:"internal_error"};
        case REPRINT_LABEL_REDUCERS_ACTION_TYPE.PRINT_SUCCESS:
            return {...state, label: action.data, isLoading: false, notificationType: NOTIFICATION_TYPE.SUCCESS,
                notificationMessageId: "label_printed_successfully", isPrintSuccess: true};
        default:
            return state;

    }
};