import Printer from "../../../print/Printer";
import {APIEvents} from "../../../constants/AnalyticsEventKeys";
import {MobileAnalyticsHelper} from "@amzn/dolphin-web-framework";
import {getNNSClient} from "../../base/ClientInterface";

export async function GetPackageLabelAPIV2(data, dispatcher, nextAction, printerErrorAction, networkErrorAction,
                                         closePrinterConnectionAfterPrint) {
    let printerConnectionStatus = await Printer.getConnectionStatus();
    if(printerConnectionStatus !== "true") {
        dispatcher({
            type: printerErrorAction
        });
        return;
    }
    let printerResolution = await Printer.getPrinterResolution();
    if (!data.printerResolution) {
        data.printerResolution = printerResolution;
    }
    let startTime = Date.now();
    getNNSClient().getPackageLabel(data)
        .then(({success, data}) => {
            if (success) {
                Printer.printLabel(data.label).then((response)=>{
                    if(response && response === true) {
                        if(closePrinterConnectionAfterPrint === true) {
                            Printer.closePrinter();
                        }
                        dispatcher({
                            type: nextAction,
                            data: data.label
                        })
                    } else {
                        dispatcher({
                            type: printerErrorAction
                        })
                    }
                });
                MobileAnalyticsHelper.executeAPIAnalytics(APIEvents.GetPackageLabel, startTime);
            } else {
                dispatcher({
                    type: networkErrorAction
                });
                MobileAnalyticsHelper.executeAPIAnalytics(APIEvents.GetPackageLabel, startTime, true);
            }
        });
}