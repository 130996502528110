import {UpdateExceptionShipmentsAPI} from "../network/apis/npsw/UpdateExceptionShipmentsAPI";
import {TARGET_PROBLEM_STATUS} from "../constants/Constants";

export const SalcolorAuditInitialState = {
    loading: false,
    loadNextComponent: false
}

export const SAL_COLOR_AUDIT_REDUCERS_ACTION_TYPE = {
    OVERRIDE_SAL_COLOR: "OVERRIDE_SAL_COLOR",
    SET_PROBLEM_ACTION_API_RESULT: "SET_PROBLEM_ACTION_API_RESULT",
    ERROR: "ERROR"
};

export const SalColorAuditReducer = (state, action) => {
    switch (action.type) {
        case SAL_COLOR_AUDIT_REDUCERS_ACTION_TYPE.OVERRIDE_SAL_COLOR: {
            UpdateExceptionShipmentsAPI({
                    scannableIdList: action.packageId,
                    problemCategory: "SAL_COLOR_MISMATCH",
                    targetProblemStatus: TARGET_PROBLEM_STATUS.RESOLVED,
                    targetAction: "MARK_SAL_COLOR_MISMATCH"
                },
                {"salColor": action.newColor, "salMissing": action.salMissing}, {},
                SAL_COLOR_AUDIT_REDUCERS_ACTION_TYPE.SET_PROBLEM_ACTION_API_RESULT,
                SAL_COLOR_AUDIT_REDUCERS_ACTION_TYPE.ERROR,
                action.dispatcher);
            return {
                ...state,
                loading: true,
                loadNextComponent: false
            }
        }

        case SAL_COLOR_AUDIT_REDUCERS_ACTION_TYPE.ERROR: {
            return {
                ...state,
                loading: false,
                loadNextComponent: true
            };
        }

        case SAL_COLOR_AUDIT_REDUCERS_ACTION_TYPE.SET_PROBLEM_ACTION_API_RESULT: {
            return {
                ...state,
                loading: false,
                loadNextComponent: true
            };
        }

        default:
            return state;
    }
}