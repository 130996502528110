import {GetPackageAttributeAPI} from "../network/apis/npsw/GetPackageAttributeAPI";
import {GetPackageAttributeAPIV2} from "../network/apis/nns/GetPackageAttributeAPI";
import {FeatureManager} from "@amzn/dolphin-web-framework";

export const GET_PACKAGE_ATTRIBUTE_ACTION_TYPE = {
  FETCH_PACKAGE_ATTRIBUTE: "FETCH_PACKAGE_ATTRIBUTE",
  SET_PACKAGE_ATTRIBUTE: "SET_PACKAGE_ATTRIBUTE",
  ERROR: "ERROR"
};

export const GetPackageAttributeReducer = (state, action) => {
  switch (action.type) {
  case GET_PACKAGE_ATTRIBUTE_ACTION_TYPE.FETCH_PACKAGE_ATTRIBUTE:
    if(FeatureManager.isFeatureEnabled(FeatureManager.Features.SAL_COLOR_AUDIT)){
      GetPackageAttributeAPIV2(action.data, action.dispatcher,
          GET_PACKAGE_ATTRIBUTE_ACTION_TYPE.SET_PACKAGE_ATTRIBUTE,
          GET_PACKAGE_ATTRIBUTE_ACTION_TYPE.ERROR);
    } else {
      GetPackageAttributeAPI(action.data, action.dispatcher,
          GET_PACKAGE_ATTRIBUTE_ACTION_TYPE.SET_PACKAGE_ATTRIBUTE,
          GET_PACKAGE_ATTRIBUTE_ACTION_TYPE.ERROR);
    }
    return {...state, loading: true};
  case GET_PACKAGE_ATTRIBUTE_ACTION_TYPE.SET_PACKAGE_ATTRIBUTE:
    return {...state, packageAttributes: action.data, loading: false};
  case GET_PACKAGE_ATTRIBUTE_ACTION_TYPE.ERROR:
    return {...state, loading: false};
  default:
    return state;
  }
};