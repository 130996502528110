import {GetPackageLabelAPI} from "../network/apis/npsw/GetPackageLabelAPI";
import {NOTIFICATION_TYPE} from "../constants/Constants";
import {useHistory} from "react-router";
import {PROBLEM_SOLVE_EXECUTION_ROUTE_PATHS} from "../router/ProblemSolveExecutionRouter";
import {FeatureManager} from "@amzn/dolphin-web-framework";
import {GetPackageLabelAPIV2} from "../network/apis/nns/GetPackageLabelAPI";

export const GET_PACKAGE_LABEL_ACTION_TYPE = {
    FETCH_PACKAGE_LABEL: "FETCH_PACKAGE_LABEL",
    SET_PACKAGE_LABEL: "SET_PACKAGE_LABEL",
    ERROR: "ERROR",
    CLOSE_NOTIFICATION: "CLOSE_NOTIFICATION",
    PRINT_SUCCESS: "PRINT_SUCCESS",
    PRINTER_ERROR: "PRINTER_ERROR",
};

export const GetPackageLabelReducer = (state, action) => {
    switch (action.type) {
        case GET_PACKAGE_LABEL_ACTION_TYPE.FETCH_PACKAGE_LABEL:
            if (FeatureManager.isFeatureEnabled(FeatureManager.Features.IS_PACKAGE_LABEL_NNS_ENABLED)) {
                GetPackageLabelAPIV2(action.data, action.dispatcher,
                    GET_PACKAGE_LABEL_ACTION_TYPE.PRINT_SUCCESS,
                    GET_PACKAGE_LABEL_ACTION_TYPE.PRINTER_ERROR,
                    GET_PACKAGE_LABEL_ACTION_TYPE.ERROR, true);
            } else {
                GetPackageLabelAPI(action.data, action.dispatcher,
                    GET_PACKAGE_LABEL_ACTION_TYPE.PRINT_SUCCESS,
                    GET_PACKAGE_LABEL_ACTION_TYPE.PRINTER_ERROR,
                    GET_PACKAGE_LABEL_ACTION_TYPE.ERROR, true);
            }
            return {...state, isLoading: true};
        case GET_PACKAGE_LABEL_ACTION_TYPE.SET_PACKAGE_LABEL:
            return {...state, label: action.data, isLoading: false};
        case GET_PACKAGE_LABEL_ACTION_TYPE.ERROR:
            return {...state, isLoading: false, notificationType: NOTIFICATION_TYPE.ALERT,
                notificationMessageId:"internal_error"};
        case GET_PACKAGE_LABEL_ACTION_TYPE.PRINTER_ERROR:
            //eslint-disable-next-line react-hooks/rules-of-hooks
            useHistory().push(({
                pathname: PROBLEM_SOLVE_EXECUTION_ROUTE_PATHS.CONNECT_PRINTER
            }));
            return {...state, isLoading: false, notificationType: NOTIFICATION_TYPE.ALERT,
                notificationMessageId:"printer_not_connect"};
        case GET_PACKAGE_LABEL_ACTION_TYPE.CLOSE_NOTIFICATION:
            return {...state, notificationType: null};
        case GET_PACKAGE_LABEL_ACTION_TYPE.PRINT_SUCCESS:
            return {...state, label: action.data, isLoading: false, notificationType: NOTIFICATION_TYPE.SUCCESS,
                notificationMessageId: "label_printed_successfully"};
        default:
            return state;
    }
};