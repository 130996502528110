export const SINGULAR_ACTION_SUCCESS_STRING_KEY = {
    UNSALVAGEABLE_PACKAGE:"ps_ready_for_fc_return_singular_success",
    DAMAGED_SALVAGEABLE: "ps_damaged_salvageable_singular_success",
    MANUAL_RETURN_TO_FC :"ps_ready_for_fc_return_singular_success",
    DONATE_AND_DESTROY: "ps_donate_destroy_singular_success",
    CLUSTER_TRANSFER_REPLAN:"ps_cluster_transfer_replan_singular_success",
    REPLAN:"ps_replan_singular_success",
    NO_OP: "success_package_marked_desc",
    WRONG_PLANNED_STATION_TRANSFER: "ps_wrong_planned_station_transfer_singular_success",
    REASSIGN_TO_ANOTHER_DRIVER: "reassign_driver_success_single",
    QR_PICKUP_EXCEPTION: "success_package_marked_desc_multiple_problem_category",
    MISSING: "ps_missing_singular_success",
    MISSORT_REPLAN: "ps_missort_replan_singular_success",
    READY_FOR_FC_RETURN: "ps_ready_for_fc_return_singular_success",
    UNASSIGN_ROUTE: "unassign_route_success_heading",
    STAGE_ITEM: "ps_stage_item_singular_success",
    ITEM_REPLAN: "ps_replan_singular_success",
    NOT_FOUND: "ps_not_found_singular_success",
    PREPARE_FOR_INDUCT: "ps_prepare_for_induct_singular_success",
    ACTION_STOW_FAIL_RTS: "ps_stow_fail_rts_success",
    RTS_DAMAGED_SALVAGEABLE: "ps_damaged_salvageable_singular_success",
    MILK_RUN: "ps_milk_run_singular_success",
    WRONG_STATION_DELIVER: "ps_i_want_deliver_singular_success",
    CARRIER_MISSORT: "ps_carrier_missort_singular_success",
    DAMAGED_UNSALVAGEABLE: "ps_damaged_unsalvageable_singular_success",
    TRANSPORTER_DISCHARGE: "discharge_successful",
    TRANSPORTER_OVERRIDE:"qr_code_override_successful",
    MOVE_TO_MANIFESTED: "ps_move_to_manifested_success",
    SELLER_REFUSED_DISPOSE_OFF: "ps_seller_refused_success",
    RTO_DESTROY:"ps_rto_destroy_singular_success",
    RTO_UNSALVAGEABLE:"ps_rto_unsalvageable_singular_success",
    RTO_REPLAN:"ps_rto_replan_singular_success",
    UNDELIVERABLE_SUSPECTED_ABUSE_RFFC: "ps_undeliverable_success",
    EMPTY_PACKAGE_DISPOSE_OFF: "ps_empty_package_success",
    MANUAL_READY_FOR_FC_RETURN: "ps_undeliverable_success",
    MARK_SAL_COLOR_MISMATCH: "ps_sal_color_mismatch_success",
    INDUCT_PRINT_LABEL: "ps_induct_relabel_required_success",
    NETWORK_MISSORT_REPLAN: "ps_network_missort_replan_sucess",
    OVERRIDE_PACKAGE_VOLUME: "ps_override_package_volume_singular_success",
    OVERRIDE_PACKAGE_SECTOR: "ps_override_package_sector_singular_success",
};

export const PLURAL_ACTION_SUCCESS_STRING_KEY = {
    MANUAL_RETURN_TO_FC:"ps_return_to_fc_plural_success",
    CLUSTER_TRANSFER_REPLAN:"ps_cluster_transfer_replan_plural_success",
    ITEM_REPLAN: "success_package_marked_multiple",
    NO_OP: "success_package_marked_multiple",
    WRONG_PLANNED_STATION_TRANSFER: "ps_wrong_planned_station_transfer_plural_success",
    REASSIGN_TO_ANOTHER_DRIVER: "reassign_driver_success_multiple",
    QR_PICKUP_EXCEPTION: "success_package_marked_desc_multiple_problem_category",
    PREPARE_FOR_INDUCT: "ps_prepare_for_induct_plural_success",
    ACTION_STOW_FAIL_RTS: "ps_stow_fail_rts_plural_success",
    MILK_RUN: "ps_milk_run_plural_success",
    WRONG_STATION_DELIVER: "ps_i_want_deliver_plural_success",
    MOVE_TO_MANIFESTED: "ps_move_to_manifested_plural_success",
    SELLER_REFUSED_DISPOSE_OFF: "ps_seller_refused_plural_success",
    UNDELIVERABLE_SUSPECTED_ABUSE_RFFC: "ps_undeliverable_plural_success",
    EMPTY_PACKAGE_DISPOSE_OFF: "ps_empty_package_plural_success",
    MANUAL_READY_FOR_FC_RETURN: "ps_undeliverable_plural_success",
    MARK_SAL_COLOR_MISMATCH: "ps_sal_color_mismatch_plural_success",
    OVERRIDE_PACKAGE_VOLUME: "ps_override_package_volume_plural_success",
    OVERRIDE_PACKAGE_SECTOR: "ps_override_package_sector_plural_success",
};

export const PARTIAL_ACTION_SUCCESS_STRING_KEY = {
    MANUAL_RETURN_TO_FC:"ps_return_to_fc_partial_success",
    UNSALVAGEABLE_PACKAGE:"ps_return_to_fc_partial_success",
    CLUSTER_TRANSFER_REPLAN:"ps_cluster_transfer_replan_partial_success",
    ITEM_REPLAN: "success_package_marked_partial",
    NO_OP: "success_package_marked_partial",
    WRONG_PLANNED_STATION_TRANSFER: "ps_wrong_planned_station_transfer_partial_success",
    REASSIGN_TO_ANOTHER_DRIVER: "reassign_driver_success_partial",
    QR_PICKUP_EXCEPTION: "success_package_marked_desc_multiple_problem_category",
    ACTION_STOW_FAIL_RTS: "ps_stow_fail_rts_partial_success",
    MILK_RUN: "ps_milk_run_partial_success",
    WRONG_STATION_DELIVER: "ps_i_want_deliver_partial_success",
    MOVE_TO_MANIFESTED: "ps_move_to_manifested_partial_success",
    SELLER_REFUSED_DISPOSE_OFF: "ps_seller_refused_partial_success",
    UNDELIVERABLE_SUSPECTED_ABUSE_RFFC: "ps_undeliverable_partial_success",
    EMPTY_PACKAGE_DISPOSE_OFF: "ps_empty_package_partial_success",
    MANUAL_READY_FOR_FC_RETURN: "ps_undeliverable_partial_success",
    MARK_SAL_COLOR_MISMATCH: "ps_sal_color_mismatch_partial_success",
    OVERRIDE_PACKAGE_VOLUME: "ps_override_package_volume_partial_success",
    OVERRIDE_PACKAGE_SECTOR: "ps_override_package_sector_partial_success",
};

export const RTO_SUCCESS_SCREEN_TEXT_MESSAGE1_STRING_KEY = {
    RTO_UNSALVAGEABLE:"ps_rtosuccessscreen_rto_unsalvageable_text_message_1",
};

export const RTO_SUCCESS_SCREEN_TEXT_MESSAGE2_STRING_KEY = {
    RTO_UNSALVAGEABLE:"ps_rtosuccessscreen_rto_unsalvageable_text_message_2",
};

export const RTO_SUCCESS_SCREEN_TEXT_MESSAGE3_STRING_KEY = {
    RTO_UNSALVAGEABLE:"ps_rtosuccessscreen_rto_unsalvageable_text_message_3",
};

export const RTO_PRINT_SUCCESS_SCREEN_TEXT_MESSAGE1_STRING_KEY = {
    RTO_REPLAN:"ps_rtoprintsuccessscreen_rto_replan_text_message_1",
    RTO_UNSALVAGEABLE:"ps_rtoprintsuccessscreen_rto_unsalvageable_text_message_1",
    RTO_PRINT_LABEL:"ps_rtoprintsuccessscreen_rto_print_label_text_message_1",
    RTS_PRINT_LABEL:"ps_rtoprintsuccessscreen_rts_print_label_text_message_1",
    RTO_DESTROY:"ps_rtoprintsuccessscreen_rto_destroy_text_message_1",
};

export const RTO_PRINT_SUCCESS_SCREEN_TEXT_MESSAGE2_STRING_KEY = {
    RTO_REPLAN:"ps_rtoprintsuccessscreen_rto_replan_text_message_2",
    RTO_UNSALVAGEABLE:"ps_rtoprintsuccessscreen_rto_unsalvageable_text_message_2",
    RTO_PRINT_LABEL:"ps_rtoprintsuccessscreen_rto_print_label_text_message_2",
    RTS_PRINT_LABEL:"ps_rtoprintsuccessscreen_rts_print_label_text_message_2",
    RTO_DESTROY:"ps_rtoprintsuccessscreen_rto_destroy_text_message_2",
};

export const RTO_PRINT_SUCCESS_SCREEN_TEXT_MESSAGE3_STRING_KEY = {
    RTO_REPLAN:"ps_rtoprintsuccessscreen_rto_replan_text_message_3",
    RTO_UNSALVAGEABLE:"ps_rtoprintsuccessscreen_rto_unsalvageable_text_message_3",
    RTO_PRINT_LABEL:"ps_rtoprintsuccessscreen_rto_print_label_text_message_3",
    RTS_PRINT_LABEL:"ps_rtoprintsuccessscreen_rts_print_label_text_message_3",
    RTO_DESTROY:"ps_rtoprintsuccessscreen_rto_destroy_text_message_3",
};

export const RTO_PRINT_SUCCESS_SCREEN_TEXT_MESSAGE4_STRING_KEY = {
    RTO_REPLAN:"ps_rtoprintsuccessscreen_rto_replan_text_message_4",
    RTO_UNSALVAGEABLE:"ps_rtoprintsuccessscreen_rto_unsalvageable_text_message_4",
    RTO_PRINT_LABEL:"ps_rtoprintsuccessscreen_rto_print_label_text_message_4",
    RTS_PRINT_LABEL:"ps_rtoprintsuccessscreen_rts_print_label_text_message_4",
    RTO_DESTROY:"ps_rtoprintsuccessscreen_rto_destroy_text_message_4",
};

export const NETWORK_MISSORT_SUCCESS_SCREEN_HEADING_STRING_KEY = {
    PRINT_NEW_LABEL:"ps_networkmissortsuccessscreen_heading_print_new_label",
    FORWARD_DIRECTLY_TO_CUSTOMER:"ps_networkmissortsuccessscreen_heading_forward_directly_to_customer",
    RETURN_TO_FC:"ps_networkmissortsuccessscreen_heading_return_to_fc",
    FORWARD_TO_3PCARRIER:"ps_networkmissortsuccessscreen_heading_forward_to_ups",
    RETURN_TO_SC:"ps_networkmissortsuccessscreen_heading_return_to_sc",
};

export const NETWORK_MISSORT_SUCCESS_SCREEN_GUIDANCE_MESSAGE_STRING_KEY = {
    FORWARD_DIRECTLY_TO_CUSTOMER:"ps_networkmissortsuccessscreen_guidance_message_forward_directly_to_customer",
    RETURN_TO_FC:"ps_networkmissortsuccessscreen_guidance_message_return_to_fc",
    FORWARD_TO_3PCARRIER:"ps_networkmissortsuccessscreen_guidance_message_forward_to_ups",
    RETURN_TO_SC: "ps_networkmissortsuccessscreen_guidance_message_return_to_sc",
};

export const NETWORK_MISSORT_SUCCESS_SCREEN_GUIDANCE_MESSAGE_STRING_KEY_FOR_LABEL_PRINT = {
    RETURN_TO_SC: "ps_networkmissortsuccessscreen_guidance_message_return_to_sc_for_label_print",
};

export const NETWORK_MISSORT_PRINT_SUCCESS_SCREEN_GUIDANCE_MESSAGE_STRING_KEY = {
    RETURN_TO_MIDDLE_MILE: "ps_networkmissort_print_successscreen_guidance_message_return_to_middle_mile",
};

