export const Modules = {
    FIXIT: "FixIt",
    GSF_FIXIT: "GSF_FIXIT"
};

export const EventNames = {
    //TODO: Send metrics for below event names
    USER_CLICK_BUTTON: "user_click_button",
    STOW_CONTAINER_SCAN: "stow_container_scan"
};

export const Descriptions = {
    ON_SCAN_FOR_PROBLEM_TYPE: "OnScanForProblemType",
    QR_CODE_SCAN: "QRCodeScan",
    UPDATE_EXCEPTION_SHIPMENT: "UpdateExceptionShipment",

    //TODO: send metrics for below descriptions
    HAZMAT_PACKAGE: "HazmatPackage",
    EXCEPTION_CATEGORY: "ExceptionCategory",
    ASIN_DETAILS_VIEWED: "ASINDetailsViewed",
    INVALID_ACTIONS: "PickMissingActivityWithInvalidActions",
    UNASSIGN_ROUTE: "UnassignRoute",
    ENTITY_SELECTED: "EntitySelected",
    DRIVER_DISCHARGE: "DriverDischarge",
    DRIVER_OVERRIDE_QR_CODE: "DriverOverrideQRCode",
    PARTIAL_FAILURE: "PartialFailure",
    LEFT_TO_FIX_GROUP_ITEM: "LeftToFixGroupItemSelected",
    LEFT_TO_FIX_GROUP: "LeftToFixGroupSelected",
    LEFT_TO_FIX_QUEQE: "LeftToFixQueueClicked",
    LEFT_TO_FIX_SOLVE: "LeftToFixSolveClicked",
    STOW_ACTIVITY_COUNT: "StowActivityCount",
    SCRUB_QUEUE: "ScrubQueueClicked"
};

export const APIEvents = {
    "GetShipmentsByScannableIds": "FixIt-getShipmentsByScannableIds",
    "GetCategoriesForNodeId":"FixIt-GetCategoriesForNodeId",
    "ExceptionShipmentSummaryReport":"FixIt-ExceptionShipmentSummaryReport",
    "GetFeatureValue":"FixIt-GetFeatureValue",
    "GetPackageAttributes":"FixIt-GetPackageAttributes",
    "GetPackageLabel":"FixIt-GetPackageLabel",
    "GetProblemSolveCategoryMap":"FixIt-getProblemSolveCategoryMap",
    "UpdateExceptionShipments" : "FixIt-UpdateExceptionShipments",
    "SearchStation":"FixIt-searchStation",
    "SearchTransporter":"FixIt-searchTransporter",
    "UpdateExceptionShipmentsForMultipleProblemCategory":"FixIt-updateExceptionShipmentsForMultipleProblemCategory",
    "StagingAreaForPicklist":"FixIt-StagingAreaForPicklist",
    "UnassignRoute":"FixIt-UnassignRoute",
    "UpdateExceptionTransporter":"FixIt-updateExceptionTransporter",
    "ExceptionShipmentSummary":"FixIt-ExceptionShipmentSummary",
    "ScrubPackage":"FixIt-ScrubPackage",
    "GetPlanForPackage": "GSF_GET_PLAN_FOR_PACKAGE_FIXIT"
};
