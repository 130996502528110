import getShipmentByScannableIdResponse from "../__mocks__/GetShipmentByScannableIdResponse.json";
import getCategoriesForNodeId from "../__mocks__/GetCategoriesforNodeId.json";
import unassignRoute from "../__mocks__/UnassignRoute.json";
import scrubPackage from "../__mocks__/ScrubPackage.json";
import getStations from "../__mocks__/GetStations";
import getFeatureValue from "../__mocks__/GetFeatureValue.json";
import getPackageAttribute from "../__mocks__/GetPackageAttribute.json";

export const NNSClient = {
    getShipmentsByScannableIdsV2: (args) => {
        console.log("NNSClient getShipmentsByScannableIdsV2 "+ args);
        return new Promise((resolve) => {
            setTimeout(() => {
                resolve({success: true, data: getShipmentByScannableIdResponse})
            }, 500)
        })
    },
    getCategoriesForNodeId: () =>{
        console.log("Request: getCategoriesForNodeId");
        return new Promise( (resolve) => {
            setTimeout(() => {
                resolve({success: true, data: getCategoriesForNodeId })
            },500)
        })
    },

    unassignRoute: (args) => {
        console.log("Request: Unassign Route, Args: ", args);
        return new Promise((resolve) => {
            setTimeout(() => {
                resolve({success: true, data: unassignRoute })
            },1000)
        })
    },

    scrubPackage: (args) => {
        console.log("Request: scrubPackage, Args: ", args);
        return new Promise( (resolve) => {
            setTimeout(() => {
                resolve({success: true, data: scrubPackage })
            },1000)
        })
    },

    getStationList: async () => {
        console.log("Request: getStationList");
        return new Promise((resolve) => {
            setTimeout(() => {
                resolve({success: true, data: getStations })
            },1000)
        })
    },

    getFeatureValue: (args) =>{
        console.log("Request: getFeatureValue, Args: ", args);
        return new Promise( (resolve) => {
            setTimeout(() => {
                resolve({success: true, data: getFeatureValue})
            },500)
        })
    },

    getPackageLabel: (args) => {
        console.log("Request: getPackageLabel, Args: ", args);
        return new Promise( (resolve) => {
            setTimeout(() => {
                resolve({success: true, data: "i am dummy label" })
            },500)
        })
    },

    getPackageAttribute: (args) =>{
        console.log("Request: getPackageAttribute, Args: ", args);
        return new Promise( (resolve) => {
            setTimeout(() => {
                resolve({success: true, data: getPackageAttribute })
            },1000)
        })
    }

};