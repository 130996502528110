import {APIEvents} from "../../../constants/AnalyticsEventKeys";
import {MobileAnalyticsHelper} from "@amzn/dolphin-web-framework";
import {getNNSClient} from "../../base/ClientInterface";

export function scrubPackageV2(data, dispatcher, errorAction) {
    let startTime = Date.now();
    getNNSClient().scrubPackage(data)
        .then(({success, data}) => {
            if (success) {
                dispatcher(data);
                MobileAnalyticsHelper.executeAPIAnalytics(APIEvents.ScrubPackage, startTime);
            } else {
                errorAction("internal_error");
                MobileAnalyticsHelper.executeAPIAnalytics(APIEvents.ScrubPackage, startTime, true);
            }
        });
}