import React, {useEffect} from 'react';
import {FormattedMessage, injectIntl} from "react-intl";
import Column from "@amzn/meridian/column";
import Alert from "@amzn/meridian/alert";
import Button from "@amzn/meridian/button";
import {ROUTE_PATH} from "../router/routing";
import {
    getexceptionResolutionResult,
    getProblemAction,
    getScannedScannableIds
} from "../dataStore/PSExecutionDataStore";
import {PROBLEM_SOLVE_EXECUTION_ROUTE_PATHS} from "../router/ProblemSolveExecutionRouter";
import PropTypes from 'prop-types';
import {withRouter} from "react-router";
import Printer from "./Printer";
import {
    clearBackPressedEventListener,
    interceptBackButton,
    interceptBackButtonStop,
    setBackPressedEventListener
} from "../components/backPressedView/BackButton";
import {SoundManager} from "../audio/SoundManager";
import {getNetworkMissortPrintSuccessScreenGuidanceMessage} from "../helper/SuccessScreenHelper";
import Text from "@amzn/meridian/text";
import {NETWORK_MISSORT_REPLAN} from "../constants/Constants";

const PrintSuccessScreen = ({intl, history, location}) => {
    const isReprintMoreLabelsSupported = checkReprintSupported(location);
    const scannableId = getScannedScannableIds()[0];
    const isNetworkMissortAction = (getProblemAction() === NETWORK_MISSORT_REPLAN);

    const getGuidanceMessage = () => {
        let result = getexceptionResolutionResult();
        let additionalInformation = result.additionalInformation[scannableId];
        let planDirection = additionalInformation.shipmentPlan.planDirection;
        return getNetworkMissortPrintSuccessScreenGuidanceMessage(planDirection);
    }

    const onBackPressListener = () => {
        handleFinishPrinting();
    };
    useEffect(() => {
        SoundManager.playSuccessSound();
        setBackPressedEventListener(onBackPressListener);
        interceptBackButton();
        return () => {
            interceptBackButtonStop();
            clearBackPressedEventListener();
        };
    }, []);

    const handleFinishPrinting = () => {
        Printer.closePrinter();
        history.push(ROUTE_PATH.HOME)
    };

    const getBottomButtonMessage = () => {
        return isNetworkMissortAction ? "solve_more_problems" : "ps_finish_printing";
    }

    return (
        <Column height="100%" heights={["fill", "fit"]}>
            <Column spacingInset="medium">
                <Alert
                    type="success"
                    size="xlarge"
                    title={intl.formatMessage({id: "success_package_marked_heading"})}>
                    {!isNetworkMissortAction &&
                        <FormattedMessage id="ps_damaged_label_singular_success"
                                          values={{trackingId: getPrintTrackingId()}}/>
                    }
                    {isNetworkMissortAction &&
                        <FormattedMessage id="ps_network_missort_print_success"
                                          values={{trackingId: scannableId}}/>
                    }
                </Alert>
                {isNetworkMissortAction &&
                    <Column width="100%" alignmentVertical="top" alignmentHorizontal="center"><Text
                        type="b300"><FormattedMessage
                        id={getGuidanceMessage()}/></Text></Column>
                }
            </Column>
            <Column width="100%" spacingInset="medium" alignmentVertical="top">
                {isReprintMoreLabelsSupported && !isNetworkMissortAction &&
                <Button type="secondary" size="large"
                        onClick={() => history.push(PROBLEM_SOLVE_EXECUTION_ROUTE_PATHS.REPRINT_LABELS)}>
                    <FormattedMessage id="ps_reprint_more_labels"/>
                </Button>
                }
                    <Button size="large" onClick={() => handleFinishPrinting()}>
                        <FormattedMessage id={getBottomButtonMessage()}/>
                    </Button>
                <div className="spaceBlockerSmall"/>
            </Column>
        </Column>
    );
};

const getPrintTrackingId = () => {
    const trackingIds = getScannedScannableIds();
    return(trackingIds[0])?trackingIds[0].substr(-4):"";
};

const checkReprintSupported = (location) => {
    return (location && location.state && location.state.properties) ?
        location.state.properties.reprintMoreLabelsSupported : true;
};

PrintSuccessScreen.propTypes = {
    intl: PropTypes.object,
    history: PropTypes.object,
    location: PropTypes.object
};

export default withRouter(injectIntl(PrintSuccessScreen));