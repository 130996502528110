import React, {useReducer} from "react";
import Text from "@amzn/meridian/text";
import { withRouter } from "react-router";
import {FormattedMessage, injectIntl} from "react-intl";
import Column from "@amzn/meridian/column";
import PropTypes from 'prop-types';
import Box from "@amzn/meridian/box";
import QRCode from "../../assets/images/ic_qr_code.png";
import {ScanWithCodeView} from "@amzn/dolphin-uiweb-framework";
import {DriverEntityReducer, DriverEntityReducerInitialState, DRIVER_ENTITY_REDUCER_ACTION_TYPE} from "../../reducers/DriverEntityReducer";
import Button from "@amzn/meridian/button";
import NotificationMessage from "../common/NotificationMessage";
import {resetUnsuccessfulQRScans} from "../../dataStore/PSExecutionDataStore";
import {PROBLEM_SOLVE_EXECUTION_ROUTE_PATHS} from "../../router/ProblemSolveExecutionRouter";
import {ENTITY_TYPES, MAX_UNSUCCESSFUL_SCAN_LIMIT, NOTIFICATION_TYPE} from "../../constants/Constants";
import {DRIVER_ENTITY_ROUTE_PATH} from "../../router/DriverEntityRouter";

const ScanQRCodeScreen = ({intl, history, location}) => {

    const [{notificationType, notificationMessageId, unsuccessfulQRScans}, dispatch] =
        useReducer(DriverEntityReducer, DriverEntityReducerInitialState);

    const onQRScan = (text) => {
        dispatch({
            type: DRIVER_ENTITY_REDUCER_ACTION_TYPE.CALL_UPDATE_EXCEPTION_TRASPORTER,
            dispatcher: dispatch,
            data: {
                transporterId: location.state.transporterId,
                problemCategory: location.state.problemCategory,
                transporterName: location.state.transporterName,
                scannedQRCode: text
            }
        })
    }

    if (unsuccessfulQRScans === MAX_UNSUCCESSFUL_SCAN_LIMIT) {
        resetUnsuccessfulQRScans();
        history.push({
            pathname: PROBLEM_SOLVE_EXECUTION_ROUTE_PATHS.COMPONENT_SELECT_DRIVER,
            state: {
                entity: ENTITY_TYPES.DRIVER,
                nextScreen: DRIVER_ENTITY_ROUTE_PATH.DRIVER_ACTION,
                notificationType: NOTIFICATION_TYPE.ALERT,
                notificationMessageId:"max_scan_attempts_limit_reached"
            }
        });
    }

    const init = {
        image: QRCode,
        onSubmit: onQRScan,
        focusLock: true
    };

    return (
        <div>
            <Box>
                <Box>
                    {notificationType &&
                    <NotificationMessage dispatch={dispatch} actionType={DRIVER_ENTITY_REDUCER_ACTION_TYPE.CLOSE_NOTIFICATION}
                                         type={notificationType} messageId={notificationMessageId} />}
                </Box>
                <Box spacingInset="medium medium small medium">
                    <Text type="h300" alignment="center"><FormattedMessage id={"scan_qr_code"}/></Text>
                </Box>
                <Column alignmentHorizontal="center">
                    <ScanWithCodeView {...init}/>
                </Column>
            </Box>
            <Column>
                <Text type="h100" alignment="center"><FormattedMessage id={"driver_discharge_qr_code_guideline_1"}/></Text>
                <Text type="h100" alignment="center"><FormattedMessage id={"driver_discharge_qr_code_guideline_2"}/></Text>
            </Column>
            <Column alignmentVertical="bottom" spacingInset="medium medium xlarge medium">
                <Button onClick={() => history.goBack()}><FormattedMessage id="cancel_text" /></Button>
            </Column>
        </div>
    )
}

ScanQRCodeScreen.propTypes = {
    history: PropTypes.object,
    location: PropTypes.object,
    intl: PropTypes.object
}

export default withRouter(injectIntl(ScanQRCodeScreen))