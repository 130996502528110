import {getErrorMessageId, processExceptionResolutionResponse} from "../helper/DaEntityHelper";
import {ENTITY_TYPES, NOTIFICATION_TYPE} from "../constants/Constants";
import {useHistory} from "react-router";
import {PROBLEM_SOLVE_EXECUTION_ROUTE_PATHS} from "../router/ProblemSolveExecutionRouter";
import {
    getProblemTypeToCategoryMap, getUnsuccessfulQRScans,
    saveExceptionResolutionResult,
    saveProblemAction,
    saveProblemCategory, setProblemTypeToCategoryMap
} from "../dataStore/PSExecutionDataStore";
import {getProblemSolveCategoryMap} from "../network/apis/npsw/GetProblemSolveCategoryMapAPI";
import {UpdateExceptionTransporterAPI} from "../network/apis/npsw/UpdateExceptionTransporterAPI";

export const DriverEntityReducerInitialState = {
    loading: false,
    notificationMessageId: "",
    transporterName: "",
    driverProblemCategories: [],
    unsuccessfulQRScans: 0
};
export const DRIVER_ENTITY_REDUCER_ACTION_TYPE = {
    GET_TRANSPORTER_PROBLEMS: "GET_TRANSPORTER_PROBLEM",
    SET_TRANSPORTER_PROBLEMS: "SET_TRANSPORTER_PROBLEM",
    CALL_UPDATE_EXCEPTION_TRASPORTER: "CALL_UPDATE_EXCEPTION_TRASPORTER",
    SET_RESPONSE: "SET_RESPONSE",
    CLOSE_NOTIFICATION: "CLOSE_NOTIFICATION",
    ERROR: "ERROR"
};

const TRANSPORTER_PROBLEM = "TRANSPORTER_PROBLEM"

export const DriverEntityReducer = (state, action) => {
    switch (action.type) {

        case DRIVER_ENTITY_REDUCER_ACTION_TYPE.CALL_UPDATE_EXCEPTION_TRASPORTER:
            saveProblemCategory(action.data.problemCategory)
            saveProblemAction(action.data.problemCategory)
            UpdateExceptionTransporterAPI(
                action.data.scannedQRCode === undefined ?
                    {
                        transporterId: action.data.transporterId,
                        problemCategory: action.data.problemCategory,
                    } : {
                        transporterId: action.data.transporterId,
                        problemCategory: action.data.problemCategory,
                        qrCode: action.data.scannedQRCode
                },
                DRIVER_ENTITY_REDUCER_ACTION_TYPE.SET_RESPONSE,
                DRIVER_ENTITY_REDUCER_ACTION_TYPE.ERROR,
                action.dispatcher,
                action.data.overrideReason
            )
            return {...state, loading: true, transporterName: action.data.transporterName}

        case DRIVER_ENTITY_REDUCER_ACTION_TYPE.SET_RESPONSE: {
            const exceptionResolutionResult = processExceptionResolutionResponse(action.data)
            saveExceptionResolutionResult(exceptionResolutionResult)
            if(exceptionResolutionResult.successCount === 0) {
                action.dispatcher ({
                    type: DRIVER_ENTITY_REDUCER_ACTION_TYPE.ERROR,
                    data: action.data,
                    dispatcher: action.dispatcher,
                })
            } else {
                //eslint-disable-next-line react-hooks/rules-of-hooks
                useHistory().push({
                    pathname: PROBLEM_SOLVE_EXECUTION_ROUTE_PATHS.SUCCESS_SCREEN,
                    state: {
                        properties: {
                            isPrintLabelSupported: false
                        },
                        metaData: {
                            transporterName: state.transporterName
                        }
                    }
                });
            }
            return {...state, loading: false}
        }
        case DRIVER_ENTITY_REDUCER_ACTION_TYPE.ERROR: {
            return {...state, loading: false, isSuccess: false,  notificationType: NOTIFICATION_TYPE.ALERT,
                notificationMessageId: getErrorMessageId(action.data), unsuccessfulQRScans: getUnsuccessfulQRScans()};
        }
        case DRIVER_ENTITY_REDUCER_ACTION_TYPE.CLOSE_NOTIFICATION: {
            return {...state, notificationType: null};
        }

        case DRIVER_ENTITY_REDUCER_ACTION_TYPE.GET_TRANSPORTER_PROBLEMS: {
            const problemTypeToCategoryMap = getProblemTypeToCategoryMap()
            if(problemTypeToCategoryMap == null) {
                getProblemSolveCategoryMap(action.data, action.dispatcher,
                    DRIVER_ENTITY_REDUCER_ACTION_TYPE.SET_TRANSPORTER_PROBLEMS, DRIVER_ENTITY_REDUCER_ACTION_TYPE.ERROR)
                return {
                    ...state,
                    loading: true
                };
            } else {
                return {
                    ...state,
                    loading: false,
                    driverProblemCategories: Object.keys(problemTypeToCategoryMap[TRANSPORTER_PROBLEM])
                };
            }
        }

        case DRIVER_ENTITY_REDUCER_ACTION_TYPE.SET_TRANSPORTER_PROBLEMS: {
            setProblemTypeToCategoryMap(action.data[ENTITY_TYPES.DRIVER])
            return {
                ...state,
                loading: false,
                driverProblemCategories: Object.keys(action.data[ENTITY_TYPES.DRIVER][TRANSPORTER_PROBLEM])
            };
        }
        default:
            return state

    }
}
