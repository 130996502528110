import {LOCAL_STORAGE_PS_EXECUTION_KEYS, LOCAL_STORAGE_PS_MODULE_KEYS} from "../constants/Constants";

export function setProblemTypeToCategoryMap(problemTypeToCategoryMap) {
    localStorage.setItem(LOCAL_STORAGE_PS_EXECUTION_KEYS.PROBLEM_TYPE_TO_CATEGORY_MAP, JSON.stringify(problemTypeToCategoryMap))
}

export function getProblemTypeToCategoryMap() {
    saveProblemCategory(null);
    saveProblemType(null);
    return JSON.parse(localStorage.getItem(LOCAL_STORAGE_PS_EXECUTION_KEYS.PROBLEM_TYPE_TO_CATEGORY_MAP));
}

export function saveScannedContent(content) {
    localStorage.setItem(LOCAL_STORAGE_PS_EXECUTION_KEYS.SCANNED_CONTENT, JSON.stringify(content));
}

export function getScannedContent() {
    return JSON.parse(localStorage.getItem(LOCAL_STORAGE_PS_EXECUTION_KEYS.SCANNED_CONTENT));
}

export function saveScannedScannableIds(scannableId) {
    // const scannedScannableIds = ["TSTKNTBBJH3EHXM"]
    let scannedScannableIds = [...getScannedScannableIds(), scannableId]
    localStorage.setItem(LOCAL_STORAGE_PS_EXECUTION_KEYS.SCANNED_SCANNABLEIDS, JSON.stringify(scannedScannableIds))
}

export function getScannedScannableIds() {
    const scannedScannableIds = JSON.parse(localStorage.getItem(LOCAL_STORAGE_PS_EXECUTION_KEYS.SCANNED_SCANNABLEIDS))
    return scannedScannableIds == null ? []:scannedScannableIds
}

export function increaseUnsuccessfulQRScans() {
    let currentUnsuccessfulQRScans = JSON.parse(localStorage.getItem(LOCAL_STORAGE_PS_EXECUTION_KEYS.UNSUCCESSFUL_QR_SCANS));
    localStorage.setItem(LOCAL_STORAGE_PS_EXECUTION_KEYS.UNSUCCESSFUL_QR_SCANS, (currentUnsuccessfulQRScans + 1));
}

export function resetUnsuccessfulQRScans() {
    localStorage.setItem(LOCAL_STORAGE_PS_EXECUTION_KEYS.UNSUCCESSFUL_QR_SCANS, JSON.parse(0));
}

export function getUnsuccessfulQRScans() {
    return localStorage.getItem(LOCAL_STORAGE_PS_EXECUTION_KEYS.UNSUCCESSFUL_QR_SCANS);
}

export function saveRetryCounter(retryCounter) {
    localStorage.setItem(LOCAL_STORAGE_PS_EXECUTION_KEYS.RETRY_COUNTER, JSON.stringify(retryCounter));
}

export function getRetryCounter() {
    return JSON.parse(localStorage.getItem(LOCAL_STORAGE_PS_EXECUTION_KEYS.RETRY_COUNTER))
}

export function saveShimentDetail(shipmentDetail) {
    let shimentDetails = [...getShipmentDetail(), ...shipmentDetail]
    localStorage.setItem(LOCAL_STORAGE_PS_EXECUTION_KEYS.SHIPMENTS_DETAIL, JSON.stringify(shimentDetails))
}

export function getShipmentDetail() {
    const shimentDetails = JSON.parse(localStorage.getItem(LOCAL_STORAGE_PS_EXECUTION_KEYS.SHIPMENTS_DETAIL))
    return shimentDetails == null ? []:shimentDetails
}

export function saveProblemType(problemType) {
    localStorage.setItem(LOCAL_STORAGE_PS_EXECUTION_KEYS.SELECTED_PROBLEM_TYPE, JSON.stringify(problemType));
    saveProblemCategory(null);
}

export function getProblemType() {
    return JSON.parse(localStorage.getItem(LOCAL_STORAGE_PS_EXECUTION_KEYS.SELECTED_PROBLEM_TYPE))
}

export function saveProblemCategory(problemCategory) {
    localStorage.setItem(LOCAL_STORAGE_PS_EXECUTION_KEYS.SELECTED_PROBLEM_CATEGORY, JSON.stringify(problemCategory))
}

export function getProblemCategory() {
    return JSON.parse(localStorage.getItem(LOCAL_STORAGE_PS_EXECUTION_KEYS.SELECTED_PROBLEM_CATEGORY))
}

export function saveProblemAction(problemAction) {
    localStorage.setItem(LOCAL_STORAGE_PS_EXECUTION_KEYS.SELECTED_PROBLEM_ACTION, JSON.stringify(problemAction))
}

export function getProblemAction() {
    return JSON.parse(localStorage.getItem(LOCAL_STORAGE_PS_EXECUTION_KEYS.SELECTED_PROBLEM_ACTION))
}

export function saveProblemCategoryConfig(problemCategoryConfig) {
    localStorage.setItem(LOCAL_STORAGE_PS_EXECUTION_KEYS.PROBLEM_CATEGORY_CONFIG, JSON.stringify(problemCategoryConfig))
}

export function getProblemCategoryConfig() {
    return JSON.parse(localStorage.getItem(LOCAL_STORAGE_PS_EXECUTION_KEYS.PROBLEM_CATEGORY_CONFIG))
}

export function saveProblemActionConfig(problemActionConfig) {
    localStorage.setItem(LOCAL_STORAGE_PS_EXECUTION_KEYS.PROBLEM_ACTION_CONFIG, JSON.stringify(problemActionConfig))
}

export function getProblemActionConfig() {
    return JSON.parse(localStorage.getItem(LOCAL_STORAGE_PS_EXECUTION_KEYS.PROBLEM_ACTION_CONFIG))
}

export function saveProblemActionNextComponentDetails(nextComponentDetails) {
    localStorage.setItem(LOCAL_STORAGE_PS_EXECUTION_KEYS.PROBLEM_ACTION_NEXT_COMPONENT_DETAILS, JSON.stringify(nextComponentDetails))
}

export function getProblemActionNextComponentDetails() {
    return JSON.parse(localStorage.getItem(LOCAL_STORAGE_PS_EXECUTION_KEYS.PROBLEM_ACTION_NEXT_COMPONENT_DETAILS))
}

export function saveExceptionResolutionResult(exceptionResolutionResult) {
    localStorage.setItem(LOCAL_STORAGE_PS_EXECUTION_KEYS.SET_EXCEPTION_RESOLUTION_RESPONSE, JSON.stringify(exceptionResolutionResult))
}

export function getexceptionResolutionResult() {
    return JSON.parse(localStorage.getItem(LOCAL_STORAGE_PS_EXECUTION_KEYS.SET_EXCEPTION_RESOLUTION_RESPONSE))
}

export function saveApiExecutionResponse(apiResponse) {
    localStorage.setItem(LOCAL_STORAGE_PS_EXECUTION_KEYS.SET_API_EXECUTION_RESPONSE, JSON.stringify(apiResponse))
}

export function getApiExecutionResponse() {
    return JSON.parse(localStorage.getItem(LOCAL_STORAGE_PS_EXECUTION_KEYS.SET_API_EXECUTION_RESPONSE))
}

export function clearPSExecutionData() {
    for(let key in LOCAL_STORAGE_PS_EXECUTION_KEYS) {
        localStorage.removeItem(key)
    }
}

export function clearPSModuleData() {
    for(let key in LOCAL_STORAGE_PS_MODULE_KEYS) {
        localStorage.removeItem(key)
    }
}

export function saveStationSectors(sectors) {
    if (sectors !== null) {
        const sectorCodes = sectors.map(s => s.sectorCode);
        const uniqueSectors = [...new Set(sectorCodes)];
        localStorage.setItem(LOCAL_STORAGE_PS_EXECUTION_KEYS.STATION_SECTORS, JSON.stringify(uniqueSectors.sort()))
    }
}

export function savePrinterMACAddress(mac) {
    localStorage.setItem(LOCAL_STORAGE_PS_MODULE_KEYS.PRINTER_MAC_ADDRESS, JSON.stringify(mac))
}

export function getPrinterMACAddress() {
    return JSON.parse(localStorage.getItem(LOCAL_STORAGE_PS_MODULE_KEYS.PRINTER_MAC_ADDRESS))
}

export function getStationSectors() {
    const sectors = JSON.parse(localStorage.getItem(LOCAL_STORAGE_PS_EXECUTION_KEYS.STATION_SECTORS))
    return sectors == null ? []: sectors
}