import React, {useEffect} from 'react';
import TopNavigation from "../common/TopNavigation";
import ScanBox from "../scanbox/ScanBox";
import Left2FixView from "../left2fix/Left2FixView";
import Column from "@amzn/meridian/column";
import {
    clearBackPressedEventListener,
    interceptBackButton,
    interceptBackButtonStop,
    setBackPressedEventListener
} from "../backPressedView/BackButton";
import {
    clearPSModuleData
} from "../../dataStore/PSExecutionDataStore";
import {NativeMeshInteractor} from "@amzn/dolphin-web-framework";
import AppContextProvider from "../backPressedView/AppContext";

const ProblemSolveOperationLauncher = () => {
    const onBackPressListener = () => {
        clearPSModuleData();
        NativeMeshInteractor.exitModule()
    };

    useEffect(() => {
        setBackPressedEventListener(onBackPressListener);
        interceptBackButton();
        return () => {
            interceptBackButtonStop();
            clearBackPressedEventListener();
        };
    }, []);

    return (
        <div>
            <AppContextProvider>
                <TopNavigation title="operation_problem_solve"/>
                <div className="content">
                    <Column height="90vh">
                        <ScanBox showChangeEntity={true} titleId="scan_packages_to_start" clearLocalStorage={true}/>
                        <Left2FixView/>
                    </Column>
                </div>
            </AppContextProvider>
        </div>
    );
};

export default ProblemSolveOperationLauncher;