import React, {useEffect, useReducer, useState} from 'react';
import {useParams, withRouter} from "react-router-dom";
import Box from "@amzn/meridian/box";
import Button from "@amzn/meridian/button";
import Text from "@amzn/meridian/text";
import {getCategoriesDisplayName} from "../../handler/TranslationHandler";
import {TaskView} from "@amzn/dolphin-uiweb-framework";
import {FormattedMessage, injectIntl} from "react-intl";
import {PROBLEM_SOLVE_EXECUTION_ROUTE_PATHS} from "../../router/ProblemSolveExecutionRouter";
import PropTypes from 'prop-types';
import {
  getActionList,
  getProblemCategoryItemDescription,
  getProblemCategoryTextColour,
  isProblemCategoryDisabled,
  isSolveLaterSupported
} from "../../helper/ProblemCategoryHelper";
import Column from "@amzn/meridian/column";
import {ScannedPackagesListView} from "../scannedpackageslist/ScannedPackagesListView";
import {
  ProblemCategoryReducer,
  ProblemCategoryReducerActionType,
  ProblemCategoryReducerInitialState
} from "../../reducers/ProblemCategoryReducer";
import {
  getProblemCategory,
  getProblemType,
  getScannedScannableIds,
  saveProblemCategory
} from "../../dataStore/PSExecutionDataStore";
import InProgress from "../common/InProgress";
import {
    INCORRECT_PACKAGE_SIZE_CATEGORY,
    MISSING_PACKAGE_SECTOR_CATEGORY,
    PROBLEM_CATEGORY_STRING_PREFIX,
    TAPE_DAMAGED_CATEGORIES
} from "../../constants/Constants";
import {isSalColorAuditRequired} from "../../utils/ShipmentUtils";

const ProblemCategory = ({history, intl}) => {
  const {problemType} = useParams();

  const [{problemCategoryConfig, problemCategories, fetchingData}, dispatch] = useReducer(ProblemCategoryReducer, ProblemCategoryReducerInitialState);
  const [[solveLaterSupported, solveLaterProblemCategory], setSolveLaterSupported] = useState([false, ""]);

  const scannedTrackingIds = getScannedScannableIds()
  useEffect(() => {
    dispatch({
      type: ProblemCategoryReducerActionType.SET_PROBLEM_TYPE,
      data: problemType,
    })
  }, [problemType]);

  const handleProblemCategoryClick = (problemCategory) => {
    if (isProblemCategoryDisabled(problemCategoryConfig[problemCategory], scannedTrackingIds)) {
      return null;
    } else {
      saveProblemCategory(problemCategory)

      if (TAPE_DAMAGED_CATEGORIES.includes(problemCategory)) {
        history.push(PROBLEM_SOLVE_EXECUTION_ROUTE_PATHS.COMPONENT_TAPE_DAMAGED);
      } else if (problemCategory === INCORRECT_PACKAGE_SIZE_CATEGORY){
          history.push(PROBLEM_SOLVE_EXECUTION_ROUTE_PATHS.SELECT_PACKAGE_SIZE);
      } else if (problemCategory === MISSING_PACKAGE_SECTOR_CATEGORY){
          history.push(PROBLEM_SOLVE_EXECUTION_ROUTE_PATHS.SELECT_SECTOR);
      } else if (isSolveLaterSupported(problemCategoryConfig[problemCategory])) {
        setSolveLaterSupported([true, problemCategory]);
      } else {
        moveToActionScreen(problemCategory)
      }
    }
  };

  const moveToActionScreen = (problemCategory) => {
    history.push({
      pathname: isSalColorAuditRequired() ? PROBLEM_SOLVE_EXECUTION_ROUTE_PATHS.SAL_COLOR_AUDIT : PROBLEM_SOLVE_EXECUTION_ROUTE_PATHS.PROBLEM_ACTION,
      state: {
        properties: {
          actionList: getActionList(problemCategoryConfig, problemCategory)
        }
      }
    });
  };

  const handleSolveNowClick = () => {
    saveProblemCategory(solveLaterProblemCategory);
    moveToActionScreen(solveLaterProblemCategory);
  };

  const handleSolveLaterClick = () => {
    saveProblemCategory(solveLaterProblemCategory);
    dispatch({
      type: ProblemCategoryReducerActionType.MARK_SOLVE_LATER,
      data: solveLaterProblemCategory,
      dispatch: dispatch
    })
  };

  const getProblemCategoryTasks = () => {
    return problemCategories.map((problemCategory) => {
      var colour = getProblemCategoryTextColour(problemCategoryConfig[problemCategory], scannedTrackingIds);
      var description = getProblemCategoryItemDescription(problemCategoryConfig[problemCategory], scannedTrackingIds, intl);
      return {
        titleColor: colour,
        descriptionColor: colour,
        title: getCategoriesDisplayName(intl,PROBLEM_CATEGORY_STRING_PREFIX + problemCategory),
        description: description,
        onClick: ()=>handleProblemCategoryClick(problemCategory),
        titleFontWeight: (description)?"":"Normal"
      }
    })
  };

  return (
    <Column height="100%" heights={["fit", "fill"]}>
      <Box>
        { fetchingData && <InProgress/>}
        <Box spacingInset="medium medium small medium">
          <Text type="h300"><FormattedMessage id={"what_would_you_like_to_do"}/></Text>
        </Box>
        <TaskView tasks={getProblemCategoryTasks()}/>
      </Box>
      <Box>
      {solveLaterSupported &&
      <Column width="100%" spacingInset="medium" alignmentVertical="bottom">
        <Button onClick={() => handleSolveNowClick()}
                disabled={solveLaterProblemCategory === "MISSING"}><FormattedMessage id="continue_solving"/></Button>
        <Button type="secondary" onClick={() => handleSolveLaterClick()}><FormattedMessage
          id="mark_a_problem"/></Button>

      </Column>
      }
      <div className="spaceBlocker"/>
      </Box>
      <ScannedPackagesListView rightHeader={getRightHeader()}/>
    </Column>
  )
};

ProblemCategory.propTypes = {
  history: PropTypes.object,
  intl: PropTypes.object
};

const getRightHeader = () => {
  if (getProblemCategory() || getProblemType())
    return PROBLEM_CATEGORY_STRING_PREFIX + (getProblemCategory() || getProblemType())
}
export default withRouter(injectIntl(ProblemCategory));