import React, {useReducer, useState} from 'react';
import {withRouter} from "react-router-dom";
import Text from "@amzn/meridian/text";
import Column from "@amzn/meridian/column";
import Button from "@amzn/meridian/button";
import {FormattedMessage, injectIntl} from "react-intl";
import {ScannedPackagesListView} from "../scannedpackageslist/ScannedPackagesListView";
import InProgress from "../common/InProgress";
import {getProblemCategory} from "../../dataStore/PSExecutionDataStore";
import {
    OVERRIDE_PACKAGE_VOLUME_ACTION,
    PackageSizes,
    PROBLEM_ACTION_API,
    PROBLEM_CATEGORY_STRING_PREFIX
} from "../../constants/Constants";
import PropTypes from 'prop-types';
import NotificationMessage from "../common/NotificationMessage";
import {
    OVERRIDE_PACKAGE_SIZE_ACTION_TYPE,
    OverridePackageSizeInitialState,
    OverridePackageSizeReducer
} from "../../reducers/OverridePackageSizeReducer";
import {TaskView} from "@amzn/dolphin-uiweb-framework";
import {
    FILL,
    FIT
} from "../../constants/PropsConstans";

const initialState = {
    packageSize: "",
};

const SelectPackageSize = ({intl, history}) => {
    const [state, setState] = useState(initialState);
    const [{loading, notificationType, notificationMessageId}, dispatcher] = useReducer(OverridePackageSizeReducer, OverridePackageSizeInitialState);

    const updatePackageSize = () => {
        dispatcher({
            type: OVERRIDE_PACKAGE_SIZE_ACTION_TYPE.OVERRIDE_PACKAGE_SIZE,
            data: {
                api: PROBLEM_ACTION_API.UPDATE_EXCEPTION_SHIPMENTS,
                properties: {
                    targetAction: OVERRIDE_PACKAGE_VOLUME_ACTION
                },
                additionalProperties: {"packageSize": state.packageSize}
            },
            dispatch: dispatcher
        })
    };

    const handleSizeSelectionClick = (size) => {
        setState({
            packageSize: size
        })
    }

    const getSizeSelectionTasks = () => {
        return PackageSizes.map((size) => {
            return {
                title: size,
                description: "",
                onClick: () => handleSizeSelectionClick(size),
                titleFontWeight: "Normal"
            }
        })
    }

    return (
        <Column height="100%" heights={[FILL, FIT]}>
            <Column spacingInset="medium">
                {loading && <InProgress/>}
                {notificationType &&
                <NotificationMessage dispatch={dispatcher} actionType={OVERRIDE_PACKAGE_SIZE_ACTION_TYPE.CLOSE_NOTIFICATION}
                                     type={notificationType} messageId={notificationMessageId} messageValues={{}}/>}
                <Text type="h300"><FormattedMessage id={"select_package_size"}/></Text>
                <TaskView tasks={getSizeSelectionTasks()}/>
            </Column>
            <Column alignmentVertical="bottom" width="100%" spacingInset="medium medium xlarge medium">
                {state.packageSize !== "" &&
                <Button onClick={() => updatePackageSize()}><FormattedMessage id="continue_solving"/></Button>}
                <Button type="secondary" onClick={() => history.goBack()}><FormattedMessage id="cancel_text"/></Button>
                <div className="spaceBlockerMedium"/>
            </Column>
            <ScannedPackagesListView rightHeader={PROBLEM_CATEGORY_STRING_PREFIX + getProblemCategory()}/>
        </Column>);
};
SelectPackageSize.propTypes = {
    history: PropTypes.object,
    intl: PropTypes.object,
};
export default withRouter(injectIntl(SelectPackageSize));