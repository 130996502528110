import {
    getProblemCategory,
    increaseUnsuccessfulQRScans,
    resetUnsuccessfulQRScans
} from "../dataStore/PSExecutionDataStore";

export const processExceptionResolutionResponse= (response) => {
    let exceptionResolutionResult = {}
    if(response.success) {
        exceptionResolutionResult = {successCount: 1, totalPackages: 1}
    } else {
        exceptionResolutionResult = {successCount: 0, totalPackages: 1}
    }
    return exceptionResolutionResult

}

export const getErrorMessageId = (data) => {
    if(getProblemCategory() === "TRANSPORTER_DISCHARGE") {
        if (data.responseCode === "QR_CODE_VALIDATION_FAILED") {
            increaseUnsuccessfulQRScans();
            return "invalid_qr_code_scanned"
        }
        resetUnsuccessfulQRScans();
        return "discharge_driver_failure"
    } else {
        return "override_qr_code_failure"
    }
}