import {unassignRoute} from "../network/apis/npsw/UnassignRouteAPI";
import {unassignRouteV2} from "../network/apis/nns/UnassignRouteAPI";
import {saveExceptionResolutionResult, saveProblemAction} from "../dataStore/PSExecutionDataStore";
import {NOTIFICATION_TYPE, UNASSIGN_ROUTE} from "../constants/Constants";
import {useHistory} from "react-router";
import {PROBLEM_SOLVE_EXECUTION_ROUTE_PATHS} from "../router/ProblemSolveExecutionRouter";
import {FeatureManager} from "@amzn/dolphin-web-framework";

export const UnassignRouteInitialState = {
    loading: false,
    isSuccess: false,
    notificationType: "",
    notificationMessageId: ""
};
export const UNASSIGN_ROTUE_ACTION_TYPE = {
    UNASSIGN_ROUTE: "UNASSIGN_ROUTE",
    UNASSIGN_ROUTE_RESPONSE: "UNASSIGN_ROUTE_RESPONSE",
    ERROR: "ERROR",
    CLOSE_NOTIFICATION: "CLOSE_NOTIFICATION"
};

export const UnassignRouteReducer = (state, action) => {
    switch (action.type) {
        case UNASSIGN_ROTUE_ACTION_TYPE.UNASSIGN_ROUTE: {
            saveProblemAction(UNASSIGN_ROUTE);
            if(FeatureManager.isFeatureEnabled(FeatureManager.Features.IS_PSEW_NNS_FLOW_ENABLED)){
                unassignRouteV2(action.data,
                    action.dispatcher,
                    UNASSIGN_ROTUE_ACTION_TYPE.UNASSIGN_ROUTE_RESPONSE,
                    UNASSIGN_ROTUE_ACTION_TYPE.ERROR);
            } else {
                unassignRoute(action.data,
                    action.dispatcher,
                    UNASSIGN_ROTUE_ACTION_TYPE.UNASSIGN_ROUTE_RESPONSE,
                    UNASSIGN_ROTUE_ACTION_TYPE.ERROR);
            }
            return {...state, loading: true};
        }
        case UNASSIGN_ROTUE_ACTION_TYPE.UNASSIGN_ROUTE_RESPONSE: {
            saveExceptionResolutionResult({successCount: 1, totalPackages: 1});
            //eslint-disable-next-line react-hooks/rules-of-hooks
            useHistory().push({
                pathname: PROBLEM_SOLVE_EXECUTION_ROUTE_PATHS.SUCCESS_SCREEN,
                state: {
                    properties: {
                        isPrintLabelSupported: false
                    }
                }
            });
            return {...state, loading: false, isSuccess: action.data.success};
        }
        case UNASSIGN_ROTUE_ACTION_TYPE.ERROR: {
            return {...state, loading: false, isSuccess: false, notificationType: NOTIFICATION_TYPE.ALERT,
                notificationMessageId:"route_unassigned_failure"};
        }
        case UNASSIGN_ROTUE_ACTION_TYPE.CLOSE_NOTIFICATION:
            return {...state, notificationType: null};
        default:
            return state;

    }
};