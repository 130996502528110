import React, {useReducer, useState, useEffect} from 'react';
import Box from "@amzn/meridian/box"
import Input from "@amzn/meridian/input";
import Text from "@amzn/meridian/text";
import Row from "@amzn/meridian/row";
import Column from "@amzn/meridian/column";
import Link from "@amzn/meridian/link";
import {FeatureManager} from "@amzn/dolphin-web-framework";
import {FormattedMessage, injectIntl} from "react-intl";
import PropTypes from 'prop-types';
import NotificationMessage from "../common/NotificationMessage"
import {
    SCAN_SHIPMENTS_REDUCERS_ACTION_TYPE,
    ScanShipmentReducer,
    ScanShipmentsnIntialState
} from "../../reducers/ScanShipmentReducer";
import InProgress from "../common/InProgress";
import {useHistory, withRouter} from "react-router-dom";
import {
    clearPSExecutionData,
    getProblemCategory,
    getScannedScannableIds,
    getShipmentDetail,
    saveProblemCategory,
    saveProblemCategoryConfig,
    saveScannedContent,
    saveScannedScannableIds,
    saveShimentDetail,
    saveStationSectors
} from "../../dataStore/PSExecutionDataStore";
import {PROBLEM_SOLVE_EXECUTION_ROUTE_PATHS} from "../../router/ProblemSolveExecutionRouter";
import {getScannablesFromQRCode, isValidJSON, isValidPackageExceptionQRCode} from "../../helper/QRExceptionsHelper";
import {ROUTE_PATH} from "../../router/routing";
import {
    NON_SWA_SHIPMENT_CATEGORY,
    PROBLEM_CATEGORY_STRING_PREFIX,
    SCAN_TYPE,
    SWA_SHIPMENT_CATEGORY,
    TAPE_DAMAGED_CATEGORIES
} from "../../constants/Constants";
import {getCategoriesDisplayName} from "../../handler/TranslationHandler";
import {getActionList, isProblemCategoryNotSupported} from "../../helper/ProblemCategoryHelper";
import {Descriptions} from "../../constants/AnalyticsEventKeys";
import {AnalyticEventKeys, Logger, MobileAnalyticsHelper} from "@amzn/dolphin-web-framework";
import {SoundManager} from "../../audio/SoundManager";
import {
    clearBackPressedEventListener,
    interceptBackButton,
    interceptBackButtonStop,
    setBackPressedEventListener
} from "../backPressedView/BackButton";
import {isSalColorAuditRequired} from "../../utils/ShipmentUtils";

const ScanBox = ({showChangeEntity, titleId, clearLocalStorage, intl}) => {
    const history = useHistory();
    const scanBoxRef = React.useRef(null);
    const [value, setValue] = useState("");
    const[{loading, notificationType, notificationMessageId, notificationMessageValues, scannedScannableId, shipmentDetail,
        shipmentDetailFetched, resetValue, scanType, problemCategoryConfig, sectors}, dispatch] =
        useReducer(ScanShipmentReducer, ScanShipmentsnIntialState)

    const onBackPressListener = () => {
        history.push(ROUTE_PATH.HOME)
    };

    useEffect(()=>{
        setInputBoxReadOnlyState(true);
        setTimeout(() => {
            setInputBoxReadOnlyState(false);
        }, 200);
        setBackPressedEventListener(onBackPressListener);
        interceptBackButton();
        return () => {
            interceptBackButtonStop();
            clearBackPressedEventListener();
        };
    },[]);

    const setInputBoxReadOnlyState = (isReadOnly) => {
        if(scanBoxRef && scanBoxRef.current){
            scanBoxRef.current.readOnly = isReadOnly;
        }
    };

    if (clearLocalStorage) {
        clearPSExecutionData()
    }

    const onScanningScannableId = (event) => {
        // 13 is for enter key code
        if(event.keyCode === 13) {
            if(isValidJSON(value)) {
                processQRCode(value, dispatch);
            } else if(getScannedScannableIds().includes(value)){
                dispatch({
                    type: SCAN_SHIPMENTS_REDUCERS_ACTION_TYPE.SHOW_DUPLICATE_PACKAGE_NOTIFICATION,
                    dispatcher: dispatch
                })
            } else {
                dispatch({
                    type: SCAN_SHIPMENTS_REDUCERS_ACTION_TYPE.FETCH_SHIPMENT_DETAILS,
                    data: [value],
                    dispatcher: dispatch,
                    scanType: SCAN_TYPE.PACKAGE
                });
                MobileAnalyticsHelper.processAnalytics(Date.now(),
                    Descriptions.ON_SCAN_FOR_PROBLEM_TYPE, " ",
                    AnalyticEventKeys.Modules.FIXIT,
                    value, " ");
            }
        }
    }

    const saveData = (scannedScannableId, shipmentDetail, sectors) => {
        saveScannedScannableIds(scannedScannableId);
        saveShimentDetail(shipmentDetail);
        saveStationSectors(sectors);
        SoundManager.playSuccessSound();
    };

    if (shipmentDetailFetched) {
        switch (scanType) {
            case SCAN_TYPE.QR_CODE:
                saveData(scannedScannableId, shipmentDetail, sectors);
                loadQRPickupExceptionsScreen(history, scannedScannableId, shipmentDetail, value);
                return (<div/>);
            default:
                const alreadyScannedPackages = getShipmentDetail();

                if (FeatureManager.isFeatureEnabled(FeatureManager.Features.SWA_SINGLE_SHIPMENT_CATEGORY_BULK_SOLVING)
                    && !allScannedPackagesAreSWAOrOther(shipmentDetail[0], alreadyScannedPackages)) {
                    const shipmentCategoryName = getShipmentCategoryName(shipmentDetail[0]);
                    showInvalidPackageShipmentCategoryScannedNotification(scannedScannableId, shipmentCategoryName, dispatch, intl,
                        "ps_ineligible_shipment_category_package_scanned");
                    return (<div/>);
                }

                if (isExceptionCategorySameAsPrevious(shipmentDetail[0].exceptionShipmentsDetails)) {
                    saveData(scannedScannableId, shipmentDetail, sectors);
                    loadNextComponent(history, problemCategoryConfig, shipmentDetail[0].exceptionShipmentsDetails[0], dispatch, scannedScannableId);
                } else if (!shipmentDetail[0].exceptionShipmentsDetails && !getProblemCategory()) {
                   if (isDestinationDomainSameAsPrevious(shipmentDetail[0])) {
                       saveData(scannedScannableId, shipmentDetail, sectors);
                       loadProblemType(history, scannedScannableId, shipmentDetail)
                   } else {
                       showInvalidPackageLegScannedNotification(scannedScannableId, shipmentDetail[0].destinationDomain, dispatch, intl,
                           "ps_ineligible_leg_package_scanned");
                   }
                } else if (shipmentDetail[0].exceptionShipmentsDetails) {
                    if (getScannedScannableIds().length > 0) {
                        showInvalidPackageScannedNotification(scannedScannableId,
                            shipmentDetail[0].exceptionShipmentsDetails[0], dispatch, intl, "ps_ineligible_package_scanned");
                    } else {
                        saveData(scannedScannableId, shipmentDetail, sectors);
                        loadNextComponent(history, problemCategoryConfig, shipmentDetail[0].exceptionShipmentsDetails[0], dispatch, scannedScannableId)
                    }
                } else {
                    showInvalidPackageScannedNotification(scannedScannableId, null, dispatch, intl,
                        "ineligible_package_scanned_no_category");
                }
                return (<div/>);
        }
    }

    if (resetValue) {
        dispatch({
            type: SCAN_SHIPMENTS_REDUCERS_ACTION_TYPE.RESET_VALUE_DONE,
            dispatcher: dispatch
        })
        setValue("")
    }
    const requestFocus = () => {
        setInputBoxReadOnlyState(true);
        setTimeout(() => {
            if(scanBoxRef && scanBoxRef.current) {
                scanBoxRef.current.focus();
                setInputBoxReadOnlyState(false);
            }
        }, 200);
    };

    return (
        <Box>
            {loading &&
            <InProgress data-testid="loader-progress"/>
            }
            {notificationType &&
            <NotificationMessage dispatch={dispatch} actionType={SCAN_SHIPMENTS_REDUCERS_ACTION_TYPE.CLOSE_NOTIFICATION}
                                 type={notificationType} messageId={notificationMessageId} messageValues={notificationMessageValues}/>}
            <Box spacingInset="medium medium small medium">
                <Row width="100%" widths={["80%", "20%"]}>
                    <Column alignmentHorizontal="left">
                        <Text type="h200" id="input_box_text"><FormattedMessage id={titleId}/></Text>
                    </Column>
                    {
                        showChangeEntity &&
                        <Column alignmentHorizontal="right">
                            <Link onClick={() => history.push(ROUTE_PATH.SELECT_ENTITY)}
                                  type="secondary"><FormattedMessage id="change_entity"/></Link>
                        </Column>
                    }
                </Row>
            </Box>
            <Box spacingInset="xxsmall medium medium medium">
                <Input
                    data-testid = "scannable-id-input"
                    aria-describedby = "input_box_text"
                    ref = {scanBoxRef}
                    value={value}
                    onChange={setValue}
                    type="text"
                    placeholder=""
                    onKeyDown={(event) => onScanningScannableId(event)}
                    size="small"
                    autoFocus={true}
                    onBlur={() => requestFocus()}
                    autoFill={false}/>
            </Box>

        </Box>
    )
};

const loadProblemType = (history, scannedScannableId, shipmentDetail) => {
    history.push({
        pathname: PROBLEM_SOLVE_EXECUTION_ROUTE_PATHS.PROBLEM_TYPE,
        state: {
            scannableId: scannedScannableId,
            shipmentDetail: shipmentDetail
        }
    })
};
const loadQRPickupExceptionsScreen = (history, scannedScannableId, shipmentDetail, value) => {
    saveScannedContent(value);
    history.push({
        pathname: PROBLEM_SOLVE_EXECUTION_ROUTE_PATHS.QR_PICKUP_EXCEPTION_SCREEN,
        state: {
            scannableId: scannedScannableId,
            shipmentDetail: shipmentDetail
        }
    })
};
const processQRCode = (QRCode, dispatch) => {
    if(isValidPackageExceptionQRCode(QRCode)) {
        dispatch({
            type: SCAN_SHIPMENTS_REDUCERS_ACTION_TYPE.FETCH_SHIPMENT_DETAILS,
            data: getScannablesFromQRCode(QRCode),
            dispatcher: dispatch,
            scanType: SCAN_TYPE.QR_CODE
        });
        MobileAnalyticsHelper.processAnalytics(Date.now(),
            Descriptions.QR_CODE_SCAN, " ",
            AnalyticEventKeys.Modules.FIXIT,
            QRCode, " ");
    } else {
        dispatch({
            type: SCAN_SHIPMENTS_REDUCERS_ACTION_TYPE.ERROR,
            dispatcher: dispatch
        })
    }
};

const loadNextComponent = (history, problemCategoryConfig, exceptionShipmentsDetail, dispatch, scannedScannableId) => {
    let problemCategory = getProblemCategoryFromShipmentDetail(exceptionShipmentsDetail)
    saveProblemCategoryConfig(problemCategoryConfig)
    saveProblemCategory(problemCategory);
    if(isProblemCategoryNotSupported(problemCategoryConfig[problemCategory])) {
        Logger.log.warn("Not supported problem category scanned - " + problemCategory);
        dispatch({
            type: SCAN_SHIPMENTS_REDUCERS_ACTION_TYPE.SHOW_INVALID_CATEGORY_NOTIFICATION,
            dispatcher: dispatch
        });
        return
    }
    if (TAPE_DAMAGED_CATEGORIES.includes(problemCategory)) {
        history.push(PROBLEM_SOLVE_EXECUTION_ROUTE_PATHS.COMPONENT_TAPE_DAMAGED);
    } else {
        history.push({
            pathname: isSalColorAuditRequired() ? PROBLEM_SOLVE_EXECUTION_ROUTE_PATHS.SAL_COLOR_AUDIT : PROBLEM_SOLVE_EXECUTION_ROUTE_PATHS.PROBLEM_ACTION,
            state: {
                properties: {
                    actionList: getActionList(problemCategoryConfig, problemCategory),
                }
            }
        });
    }
}

const showInvalidPackageScannedNotification = (scannableId, exceptionShipmentsDetail, dispatch, intl, notificationId) => {
    dispatch({
        type: SCAN_SHIPMENTS_REDUCERS_ACTION_TYPE.SHOW_INVALID_PACKAGE_SCANNED_NOTIFICATION,
        data: {
            scannableId: scannableId.substr(scannableId.length-4),
            problemCategory: getCategoriesDisplayName(intl,PROBLEM_CATEGORY_STRING_PREFIX + getProblemCategoryFromShipmentDetail(exceptionShipmentsDetail)),
            notification: notificationId
        },
        dispatcher: dispatch
    })
}

const showInvalidPackageLegScannedNotification = (scannableId, destinationDomain, dispatch, intl, notificationId) => {
    dispatch({
        type: SCAN_SHIPMENTS_REDUCERS_ACTION_TYPE.SHOW_INVALID_PACKAGE_LEG_SCANNED_NOTIFICATION,
        data: {
            scannableId: scannableId.substr(scannableId.length-4),
            destinationDomain: getCategoriesDisplayName(intl,PROBLEM_CATEGORY_STRING_PREFIX + destinationDomain),
            notification: notificationId
        },
        dispatcher: dispatch
    })
}

const getShipmentCategoryName = (shipmentDetail) => {
    return shipmentDetail?.shipmentCategory === SWA_SHIPMENT_CATEGORY
        ? SWA_SHIPMENT_CATEGORY
        : NON_SWA_SHIPMENT_CATEGORY;
};

const allScannedPackagesAreSWAOrOther = (shipmentDetail, alreadyScannedPackages) => {
    return alreadyScannedPackages.length === 0 || (shipmentDetail?.shipmentCategory === SWA_SHIPMENT_CATEGORY
        ? alreadyScannedPackages[0].shipmentCategory === SWA_SHIPMENT_CATEGORY
        : alreadyScannedPackages[0].shipmentCategory !== SWA_SHIPMENT_CATEGORY);
}

const showInvalidPackageShipmentCategoryScannedNotification = (scannableId, shipmentCategory, dispatch, intl, notificationId) => {
    const currentShipmentCategory = shipmentCategory === SWA_SHIPMENT_CATEGORY ? NON_SWA_SHIPMENT_CATEGORY : SWA_SHIPMENT_CATEGORY;

    dispatch({
        type: SCAN_SHIPMENTS_REDUCERS_ACTION_TYPE.SHOW_INVALID_SHIPMENT_CATEGORY_SCANNED_NOTIFICATION,
        data: {
            scannableId: scannableId.substr(scannableId.length-4),
            shipmentCategory: shipmentCategory,
            currentShipmentCategory: currentShipmentCategory,
            notification: notificationId
        },
        dispatcher: dispatch
    })
}

const getProblemCategoryFromShipmentDetail = (exceptionShipmentsDetail) => {
    let problemCategory = "";
    if(exceptionShipmentsDetail && exceptionShipmentsDetail.exceptionShipment) {
        problemCategory = exceptionShipmentsDetail.exceptionShipment.category
    }
    return problemCategory
}

const isExceptionCategorySameAsPrevious = (exceptionShipmentsDetails)=>{
    return exceptionShipmentsDetails
        && exceptionShipmentsDetails.length
        && (getProblemCategory() === getProblemCategoryFromShipmentDetail(exceptionShipmentsDetails[0]));
};

const isDestinationDomainSameAsPrevious = (shipmentDetail) => {
    const isMultipleShipments = getShipmentDetail().length;
    if( isMultipleShipments === 0 || (isMultipleShipments > 0 &&
        shipmentDetail.destinationDomain === getShipmentDetail()[0].destinationDomain)) {
        return true;
    }
    return false;
}

ScanBox.propTypes = {
    showChangeEntity: PropTypes.any,
    titleId: PropTypes.string,
    history: PropTypes.object,
    clearLocalStorage: PropTypes.any,
    intl: PropTypes.object
}

export default withRouter(injectIntl(ScanBox))