import {
    NOTIFICATION_TYPE,
    OVERRIDE_PACKAGE_VOLUME_ACTION,
} from "../constants/Constants";
import {
    callActionApi,
    getMessageAndAlertTypeForUpdateExceptionShipment,
    handleInlineError,
    processApiExecutionResponse
} from "../helper/ActionComponentsHelper";
import {PROBLEM_SOLVE_EXECUTION_ROUTE_PATHS} from "../router/ProblemSolveExecutionRouter";
import {useHistory} from "react-router-dom";
import {saveExceptionResolutionResult, saveProblemAction} from "../dataStore/PSExecutionDataStore";

export const OverridePackageSizeInitialState = {
    loading: false,
    notificationType: "",
    notificationMessageId: ""
};

export const OVERRIDE_PACKAGE_SIZE_ACTION_TYPE = {
    OVERRIDE_PACKAGE_SIZE: "OVERRIDE_PACKAGE_SIZE",
    OVERRIDE_PACKAGE_SIZE_RESPONSE: "OVERRIDE_PACKAGE_SIZE_RESPONSE",
    ERROR: "ERROR",
    HANDLE_INLINE_ERROR: "HANDLE_INLINE_ERROR",
    CLOSE_NOTIFICATION: "CLOSE_NOTIFICATION",
};

export const OverridePackageSizeReducer = (state, action) => {
    switch (action.type) {
        case OVERRIDE_PACKAGE_SIZE_ACTION_TYPE.OVERRIDE_PACKAGE_SIZE:
            saveProblemAction(OVERRIDE_PACKAGE_VOLUME_ACTION);
            callActionApi(
                action.data,
                action.dispatch,
                OVERRIDE_PACKAGE_SIZE_ACTION_TYPE.OVERRIDE_PACKAGE_SIZE_RESPONSE,
                OVERRIDE_PACKAGE_SIZE_ACTION_TYPE.ERROR
            );
            return {...state, loading: true};
        case OVERRIDE_PACKAGE_SIZE_ACTION_TYPE.OVERRIDE_PACKAGE_SIZE_RESPONSE: {
            const {exceptionResolutionResult} = processApiExecutionResponse(action.data);
            if(exceptionResolutionResult.isError) {
                handleInlineError(action.data, OVERRIDE_PACKAGE_SIZE_ACTION_TYPE.HANDLE_INLINE_ERROR, action.dispatcher)
                return {...state};
            }
            saveExceptionResolutionResult(exceptionResolutionResult);
            //eslint-disable-next-line react-hooks/rules-of-hooks
            useHistory().push({
                pathname: PROBLEM_SOLVE_EXECUTION_ROUTE_PATHS.SUCCESS_SCREEN,
                state: {
                    properties: {
                        isPrintLabelSupported: false
                    },
                    metaData: action.metaData
                }
            });
            return {...state, loading: false};
        }
        case OVERRIDE_PACKAGE_SIZE_ACTION_TYPE.HANDLE_INLINE_ERROR:
            const alertParameters = getMessageAndAlertTypeForUpdateExceptionShipment(action.data)
            return {...state, loading: false, notificationType: alertParameters.alertType,
                notificationMessageId: alertParameters.alertMessageId}
        case OVERRIDE_PACKAGE_SIZE_ACTION_TYPE.ERROR:
            return {
                ...state, loading: false, notificationType: NOTIFICATION_TYPE.ALERT,
                notificationMessageId: "internal_error"
            };
        case OVERRIDE_PACKAGE_SIZE_ACTION_TYPE.CLOSE_NOTIFICATION:
            return {...state, notificationType: null};
        default:
            return state;
    }
};