import React, {useEffect, useReducer} from 'react';
import Button from "@amzn/meridian/button";
import Column from "@amzn/meridian/column";
import Alert from "@amzn/meridian/alert";
import {useHistory, withRouter} from "react-router-dom";
import {ROUTE_PATH} from "../../router/routing";
import PropTypes from 'prop-types';
import {getSecondaryTitle} from "../../helper/SuccessScreenHelper";
import {FormattedMessage, injectIntl} from "react-intl";
import {
    getexceptionResolutionResult,
    getProblemAction,
    getProblemCategory,
    getScannedScannableIds, resetUnsuccessfulQRScans
} from "../../dataStore/PSExecutionDataStore";
import {getPackageLabelType, isPrintingSupported} from "../../print/PrintHelper";
import {GET_PACKAGE_LABEL_ACTION_TYPE, GetPackageLabelReducer} from "../../reducers/GetPackageLabelReducer";
import InProgress from "../common/InProgress";
import NotificationMessage from "../common/NotificationMessage";
import {ProblemActionReducerActionType} from "../../reducers/ProblemActionReducer";
import Printer from "../../print/Printer";
import {PROBLEM_SOLVE_EXECUTION_ROUTE_PATHS} from "../../router/ProblemSolveExecutionRouter";
import {
    clearBackPressedEventListener,
    interceptBackButton,
    interceptBackButtonStop,
    setBackPressedEventListener
} from "../backPressedView/BackButton";
import {SoundManager} from "../../audio/SoundManager";

const SuccessScreen = (props) => {
    const [{isLoading, notificationType, notificationMessageId}, dispatch] = useReducer(GetPackageLabelReducer, {isLoading: false});
    const result = getexceptionResolutionResult();
    const metaData = (props.location.state)? props.location.state.metaData : {};
    const secondaryTitle = getSecondaryTitle(result.successCount, result.totalPackages,
        getProblemAction(), getScannedScannableIds(), getProblemCategory(), metaData);
    const nextScreen = (props.location.state)? props.location.state.nextScreenAfterSuccess:null;
    const history = useHistory();

    const onBackPressListener = () => {
        history.push(ROUTE_PATH.HOME);
    };

    useEffect(() => {
        SoundManager.playSuccessSound();
        setBackPressedEventListener(onBackPressListener);
        interceptBackButton();
        resetUnsuccessfulQRScans();

        return () => {
            if(isPrintingSupported()){Printer.closePrinter()}
            interceptBackButtonStop();
            clearBackPressedEventListener();
        }
    }, []);

    return (
        <Column height="100%" heights={["fill","fit"]}>
            <Column spacingInset="medium">
                {isLoading && <InProgress/>}
                {notificationType && <NotificationMessage dispatch={dispatch} actionType={ProblemActionReducerActionType.CLOSE_NOTIFICATION}
                                                          type={notificationType} messageId={notificationMessageId}/>}
                <br/><br/>
                <Alert
                    type="success"
                    size="xlarge"
                    title={props.intl.formatMessage({id: "success_package_marked_heading"})}>{secondaryTitle}</Alert>
            </Column>
            <Column width="100%" spacingInset="medium" alignmentVertical="top">
                {isPrintingSupported() &&
                <Button type="secondary" size="large"
                        onClick={() => handlePrintCommand(props.history, dispatch)}>Print
                    label</Button>}
                <Button size="large" onClick={() => history.push(nextScreen || ROUTE_PATH.HOME)}>
                    <FormattedMessage id="solve_more_problems"/>
                </Button>
                <div className="spaceBlockerMedium"/>
            </Column>
        </Column>
    );
};

const handlePrintCommand = (history, dispatch) => {
    Printer.getConnectionStatus().then((connectionStatus) => {
        if (connectionStatus === "true") {
            dispatch({
                type: GET_PACKAGE_LABEL_ACTION_TYPE.FETCH_PACKAGE_LABEL,
                data: {
                    scannableId: getScannedScannableIds()[0],
                    labelType: getPackageLabelType()
                },
                dispatcher: dispatch
            });
        } else {
            history.push(PROBLEM_SOLVE_EXECUTION_ROUTE_PATHS.CONNECT_PRINTER);
        }
    }).catch(()=>{
        history.push(PROBLEM_SOLVE_EXECUTION_ROUTE_PATHS.CONNECT_PRINTER);
    });

};

SuccessScreen.propTypes = {
    primaryTitle: PropTypes.string,
    imageUrl: PropTypes.string,
    isPrintLabelSupported: PropTypes.bool,
    intl: PropTypes.object,
    history: PropTypes.object,
    location: PropTypes.object
};

export default withRouter(injectIntl(SuccessScreen));