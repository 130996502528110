import React, {useReducer, useState} from 'react';
import Column from "@amzn/meridian/column";
import Text from "@amzn/meridian/text";
import {FormattedMessage, injectIntl} from "react-intl";
import Select, {SelectOption} from "@amzn/meridian/select";
import {
    SEARCH_TRANSPORTER_ACTION_TYPE,
    SearchTransporterInitialState,
    SearchTransporterReducer
} from "../../reducers/SearchTransporterReducer";
import {getDisplayName} from "../../handler/TranslationHandler";
import Button from "@amzn/meridian/button";
import PropTypes from 'prop-types';
import {
    getProblemCategory
} from "../../dataStore/PSExecutionDataStore";
import {
    getDriverActionTransporter, getDriverEntityState,
    saveDriverActionTransporter
} from "../../dataStore/LocationStateDataStore";
import {ScannedPackagesListView} from "../scannedpackageslist/ScannedPackagesListView";
import InProgress from "../common/InProgress";
import {ENTITY_TYPES, PROBLEM_CATEGORY_STRING_PREFIX} from "../../constants/Constants";
import {withRouter} from "react-router-dom";
import NotificationMessage from "../common/NotificationMessage";
import {getRegion} from "../../config/NetConf";
import {Constants} from "@amzn/dolphin-web-framework";

const initialState = {
    selectedDA: "",
    searchDA: "",
    DAName: ""
};

const SelectDriver = ({history, intl, location}) => {

    if(!location.state){
        location.state = getDriverEntityState();
    }

    const [state, setState] = useState(initialState);
    let [{transporters, loading, notificationType, notificationMessageId}, dispatcher] = useReducer(SearchTransporterReducer, SearchTransporterInitialState);
    const showTransporterPersonalDetails = getRegion() !== Constants.Region.REGION_EU;

    const entity = getEntity(location)
    
    const getTransportersList = (searchQuery) => {
        setState({...state, searchDA: searchQuery});
        if (searchQuery.length >= 3) {
            dispatcher({
                type: SEARCH_TRANSPORTER_ACTION_TYPE.FETCH_TRANSPORTER,
                data: {searchKeyword: searchQuery},
                dispatcher: dispatcher
            })
        }
    };

    if (location.state && location.state.notificationType) {
        notificationType = location.state.notificationType;
        notificationMessageId = location.state.notificationMessageId;
        location.state.notificationType = null;
    }

    const assignDriver = () => {
        let transporter;
        for (let index in transporters) {
            if (transporters[index].transporterId === state.selectedDA) {
                transporter = transporters[index];
            }
        }

        if (entity === ENTITY_TYPES.DRIVER) {
            saveDriverActionTransporter(transporter)
            history.push({
                pathname: getNextScreen(location),
                state: {
                    transporter: getDriverActionTransporter(),
                    metaData: getMetaData(location, transporter)
                }
            });
        } else {
            dispatcher({
                type: SEARCH_TRANSPORTER_ACTION_TYPE.CHANGE_TRANSPORTER,
                data: {transporterId: state.selectedDA, transporterName: getTransportersDetails(showTransporterPersonalDetails, transporter)},
                dispatcher: dispatcher
            })
        }
        return <div/>
    };

    return (
        <Column height="100%" heights={["fill", "fit"]}>
            <Column spacingInset="medium">
                {loading && <InProgress/>}
                {notificationType &&
                <NotificationMessage dispatch={dispatcher} actionType={SEARCH_TRANSPORTER_ACTION_TYPE.CLOSE_NOTIFICATION}
                                     type={notificationType} messageId={notificationMessageId}/>}
                <Text type="h300"><FormattedMessage id={"what_would_you_like_to_do"}/></Text>
                <Select value={state.selectedDA}
                        onChange={(selectedDA) => setState({...state, selectedDA: selectedDA})}
                        searchQuery={state.searchDA}
                        onSearch={(searchQuery) => getTransportersList(searchQuery)}
                        placeholder={getDisplayName(intl, "search_driver_name")}>

                    {transporters.map((transporter) => (<SelectOption
                        label={getLabel(showTransporterPersonalDetails, transporter)}
                        value={transporter.transporterId}
                        key={transporter.transporterId}/>))}

                    {!transporters.length ? (<Column
                        alignmentVertical="center"
                        spacing="small"
                        spacingInset="xlarge">
                        <Text alignment="center">
                            <FormattedMessage id="no_results"/>
                        </Text>
                    </Column>) : null}

                </Select>
            </Column>
            <Column width="100%" alignmentVertical="bottom" spacingInset="medium medium xlarge medium">
                {state.selectedDA !== "" &&
                <Button onClick={() => assignDriver()}><FormattedMessage id="continue_solving"/></Button>}
                <Button type="secondary" onClick={() => history.goBack()}><FormattedMessage id="cancel_text"/></Button>
                <div className="spaceBlockerMedium"/>
            </Column>
            {entity !== ENTITY_TYPES.DRIVER &&
            <ScannedPackagesListView rightHeader={PROBLEM_CATEGORY_STRING_PREFIX + getProblemCategory()}/>}
        </Column>
    );
};

const getLabel = (showTransporterPersonalDetails, transporter) => {
    const parameters = showTransporterPersonalDetails ? [transporter.transporterName, transporter.email]
        : [transporter.transporterId, transporter.shortCode];
    return parameters.filter(param => !!param).join(' | ')
}

function getTransportersDetails(showTransporterPersonalDetails, transporter) {
    if (showTransporterPersonalDetails)
        return transporter.transporterName;
    return [transporter.transporterId, transporter.shortCode].filter(param => !!param).join('/')
}

const getEntity = (location) => {
    let entity = ENTITY_TYPES.PACKAGE
    if (location.state) {
        entity = location.state.entity
    }
    return entity
};

const getNextScreen = (location) => {
    return location.state.nextScreen;
};

const getMetaData = (location, transporter) => {
  if(location.state && location.state.metaData) {
      return {...location.state.metaData,
          newTransporterId: transporter.transporterId};
  }
};

SelectDriver.propTypes = {
    history: PropTypes.object,
    intl: PropTypes.object,
    location: PropTypes.object
};
export default withRouter(injectIntl(SelectDriver));