import React, {useEffect, useReducer} from 'react';
import Text from "@amzn/meridian/text";
import Box from "@amzn/meridian/box";
import {FormattedMessage} from "react-intl";
import {getDisplayName} from "../../handler/TranslationHandler";
import {TaskView} from "@amzn/dolphin-uiweb-framework";
import Button from "@amzn/meridian/button";
import Column from "@amzn/meridian/column";
import {PROBLEM_SOLVE_EXECUTION_ROUTE_PATHS} from "../../router/ProblemSolveExecutionRouter";
import PropTypes from 'prop-types';
import InProgress from "../common/InProgress";
import {
    ACTION_TYPES_WITH_DESCRIPTION,
    COMMON_PROBLEM_SOLVE_PREFIX,
    HAZMAT_LABEL_CHECK_ELIGIBLE_ACTION,
    PROBLEM_CATEGORY_STRING_PREFIX
} from "../../constants/Constants";
import {
    ProblemActionReducer,
    ProblemActionReducerActionType,
    ProblemActionReducerInitialState
} from "../../reducers/ProblemActionReducer";
import {getShipmentDetail} from "../../dataStore/PSExecutionDataStore";
import {getHazmatCode, isHazmatPackage, updateNextComponentDetails} from "../../helper/ActionComponentsHelper";
import {getProblemCategory, getScannedScannableIds} from "../../dataStore/PSExecutionDataStore";
import {ScannedPackagesListView} from "../scannedpackageslist/ScannedPackagesListView";
import NotificationMessage from "../common/NotificationMessage";
import {AnalyticEventKeys, Logger, MobileAnalyticsHelper} from "@amzn/dolphin-web-framework";
import ScanBox from "../scanbox/ScanBox";
import {
    isAutoResolveExceptionCategory,
    isScanBoxEnabledForProblemCategory,
    isShowProductListSupported
} from "../../helper/ProblemActionHelper";
import Printer from "../../print/Printer";
import {FeatureManager} from "@amzn/dolphin-web-framework";
import {
    getActionListFromDataStore, getAdditionalProperties,
    getNextScreenAfterSuccess, saveActionList, saveAdditionalProperties,
    saveNextScreenAfterSuccess
} from "../../dataStore/LocationStateDataStore";
import WarningScreen from "../common/WarningScreen";

const ProblemAction = (props) => {

    const history = props.history;
    const [{
        fetchingData, loadNextComponent, nextComponentDetails, notificationType, notificationMessageId, exceptionResolutionResult,
        notificationMessageValues, counter, warningText, networkMissortAction
    }, dispatch] = useReducer(ProblemActionReducer, ProblemActionReducerInitialState);

    if(!props.location.state){
        props.location.state = {
            properties: {
                actionList: getActionListFromDataStore(),
            },
            additionalProperties: getAdditionalProperties(),
            nextScreenAfterSuccess: getNextScreenAfterSuccess()
        }
    }

    const actionList = props.location.state.properties.actionList;
    const nextScreen = props.location.state.nextScreenAfterSuccess;
    const additionalProperties = props.location.state.additionalProperties;
    saveActionList(actionList)
    saveNextScreenAfterSuccess(nextScreen)
    saveAdditionalProperties(additionalProperties)

    useEffect(()=>{
        return () => {
            if(FeatureManager.isFeatureNotEnabled(FeatureManager.Features.SKIP_PRINTER_DISCONNECTION)) {
                Printer.closePrinter();
            }
        };
    },[]);

    useEffect(()=>{
        if (actionList && isAutoResolveExceptionCategory(actionList[0])) {
            dispatch({
                type: ProblemActionReducerActionType.GET_PROBLEM_ACTION_CONFIG,
                data: actionList[0],
                dispatch: dispatch
            })
        }
    },[]);

    if (loadNextComponent) {
        Logger.log.info("Loading next Component details from Problem Action Screen.");
        updateNextComponentDetails()

        if (nextComponentDetails.api) {
            dispatch({
                type: ProblemActionReducerActionType.EXECUTE_PROBLEM_ACTION_API,
                data: {
                    api: nextComponentDetails.api,
                    properties: nextComponentDetails.properties,
                    additionalProperties: additionalProperties
                },
                dispatch: dispatch
            })
        } else {
            history.replace({
                pathname: `${COMMON_PROBLEM_SOLVE_PREFIX}${nextComponentDetails.component}`,
                state: {
                    properties: nextComponentDetails.properties,
                    additionalProperties: additionalProperties,
                    nextScreenAfterSuccess: nextScreen
                }
            });
            return <div/>
        }
    }

    if (exceptionResolutionResult.isError) {
        Logger.log.error(getDisplayName(props.intl, "internal_error"))
        dispatch({
            type: ProblemActionReducerActionType.SET_PROBLEM_ACTION_API_RESULT_ERROR,
            dispatcher: dispatch
        })
    }
    const handleActionClick = (action) => {
        Logger.log.info("Operator clicked on action button:", action);
        if (HAZMAT_LABEL_CHECK_ELIGIBLE_ACTION.includes(action)
            && isHazmatPackage()) {
            additionalProperties.hazmatCode = getHazmatCode();
            history.push({
                pathname: PROBLEM_SOLVE_EXECUTION_ROUTE_PATHS.HAZMAT_SCAN,
                state: {
                    additionalProperties: additionalProperties
                }
            })
        } else {
            dispatch({
                type: ProblemActionReducerActionType.GET_PROBLEM_ACTION_CONFIG,
                data: action,
                dispatch: dispatch
            })

            if (getScannedScannableIds() && getProblemCategory()) {
                getScannedScannableIds().forEach(shipment => {
                    MobileAnalyticsHelper.processAnalytics(Date.now(), getProblemCategory(), shipment, AnalyticEventKeys.Modules.PROBLEM_SOLVE,
                        shipment, action, AnalyticEventKeys.Events.USER_SCANNED_PACKAGE)
                });
            }
        }
    };

    const getDescription = (action) => {
        if (ACTION_TYPES_WITH_DESCRIPTION.includes(action)) {
            return getDisplayName(props.intl, "action_description_" + action);
        } else {
            return "";
        }
    };

    const getProblemActionTasks = () => {
        return actionList.map((action) => {
            var description = getDescription(action);
            return {
                title: getDisplayName(props.intl, "action_" + action),
                description: description,
                onClick: () => handleActionClick(action),
                titleFontWeight: (description) ? "" : "Normal"
            }
        })
    };

    return (
        <Column height="100%" heights={["fit", "fill"]}>
            <Box spacingInset="none">
                {fetchingData && !networkMissortAction && <InProgress/>}
                {fetchingData &&  networkMissortAction &&
                    <InProgress heading={props.intl.formatMessage({id: "ps_network_missort_loading_heading"})}
                                subHeading={props.intl.formatMessage({id: "ps_network_missort_loading_subheading"})}
                                scannableId={getShipmentDetail()[0].scannableId}/>}
                {counter === 5 && <WarningScreen text={warningText}/>}
                {notificationType &&
                <NotificationMessage dispatch={dispatch} actionType={ProblemActionReducerActionType.CLOSE_NOTIFICATION}
                                     type={notificationType} messageId={notificationMessageId}
                                     messageValues={notificationMessageValues}/>}

                {isScanBoxEnabledForProblemCategory() &&
                <ScanBox showChangeEntity={false} titleId="scan_item_id" clearLocalStorage={false}/>}
                {!fetchingData &&
                    <div>
                    <Box spacingInset="medium medium small medium">
                        <Text type="h300"><FormattedMessage id={"what_would_you_like_to_do"}/></Text>
                    </Box>
                    <TaskView tasks={getProblemActionTasks()}/>
                    </div>
                }

            </Box>
            <Column width="100%" spacingInset="medium" alignmentVertical="bottom">
                {isShowProductListSupported() &&
                <Button size="large" type="secondary"
                        onClick={() => history.push(PROBLEM_SOLVE_EXECUTION_ROUTE_PATHS.PRODUCT_ITEMS)}>
                    <FormattedMessage id={"ps_product_list"}/>
                </Button>}
                <div className="spaceBlocker"/>
            </Column>
            <ScannedPackagesListView rightHeader={PROBLEM_CATEGORY_STRING_PREFIX + getProblemCategory()}/>
        </Column>
    );
};

ProblemAction.propTypes = {
    history: PropTypes.object,
    location: PropTypes.object,
    intl: PropTypes.object
};
export default ProblemAction;