import Column from "@amzn/meridian/column";
import React, {useReducer, useState, useRef, useEffect, useContext} from "react";
import Select, {SelectOption} from "@amzn/meridian/select"
import {PROBLEM_SOLVE_EXECUTION_ROUTE_PATHS} from "../../router/ProblemSolveExecutionRouter";
import Text from "@amzn/meridian/text";
import {FormattedMessage} from "react-intl";
import Box from "@amzn/meridian/box";
import Row from "@amzn/meridian/row";
import {
    getScannedScannableIds,
    getShipmentDetail
} from "../../dataStore/PSExecutionDataStore";
import Button from "@amzn/meridian/button";
import {
    SAL_COLOR_AUDIT_REDUCERS_ACTION_TYPE,
    SalcolorAuditInitialState,
    SalColorAuditReducer
} from "../../reducers/SalColorAuditReducer";
import InProgress from "../common/InProgress";
import {SAL_COLOR_MAP} from "../../constants/Constants";
import Alert from "@amzn/meridian/alert";
import {
    getActionListFromDataStore,
    getAdditionalProperties, saveActionList, saveAdditionalProperties
} from "../../dataStore/LocationStateDataStore";
import {AppContext} from "../backPressedView/AppContext";

const SalColorAudit = ({intl, history, location}) => {
    const[{loading, loadNextComponent}, dispatch]= useReducer(SalColorAuditReducer, SalcolorAuditInitialState);
    const {appActions} = useContext(AppContext);

    if(!location.state){
        location.state = {
            properties: {
                actionList: getActionListFromDataStore(),
            },
            additionalProperties: getAdditionalProperties(),
        }
    }

    const packageId = getScannedScannableIds();
    const actionList = location.state.properties.actionList;
    const additionalProperties = location.state.additionalProperties;
    const shipmentDetail = getShipmentDetail();
    const salColorHexCode = shipmentDetail.length > 0 ? shipmentDetail[0].salColor : ""
    saveActionList(actionList)
    saveAdditionalProperties(additionalProperties)

    const [mismatch, setMismatch] = useState(false)
    const [selectedValue, setSelectedValue] = useState("")
    const [showNotification, setShowNotification] = useState(false);
    const notification = useRef({id: "", defaultMessage: ""});

    const updateNextComponentDetails = () => {
        history.push({
            pathname: PROBLEM_SOLVE_EXECUTION_ROUTE_PATHS.PROBLEM_ACTION,
            state: {
                properties: {
                    actionList: actionList
                },
                additionalProperties: additionalProperties
            }
        });
    }

    if (loadNextComponent) {
        updateNextComponentDetails();
    }

    const overrideSalColor = (color) => {
        dispatch({
            type: SAL_COLOR_AUDIT_REDUCERS_ACTION_TYPE.OVERRIDE_SAL_COLOR,
            dispatcher: dispatch,
            packageId: packageId,
            newColor: color,
            salMissing: false
        })
    }
    const confirmSalColor = () => {
        if (selectedValue === salColorHexCode) {
            updateNextComponentDetails();
        } else {
            setMismatch(true);
            overrideSalColor("");
        }
    }

    const helpOptions = {
        list: [{
            title: intl.formatMessage({id: "help.sal_missing", defaultMessage: "SAL missing on package"}),
            onClick: () => {
                dispatch({
                    type: SAL_COLOR_AUDIT_REDUCERS_ACTION_TYPE.OVERRIDE_SAL_COLOR,
                    dispatcher: dispatch,
                    packageId: packageId,
                    salMissing: true
                })
                updateNextComponentDetails();
            }
        }]
    }

    useEffect(() => {
        appActions.setupHelp(helpOptions);
    }, []);

    return (
        <Column height="100%" spacing="none" spacingInset="none">
            {loading && <InProgress/>}
            {showNotification && <Row width="100%" widths="fill" spacingInset="medium medium none medium">
                <Alert type="error" size="medium" onClose={() => setShowNotification(false)}>
                    <FormattedMessage id={notification.current.id} defaultMessage={notification.current.defaultMessage}/>
                </Alert>
            </Row>}
            {!mismatch && <Box spacingInset="medium medium small medium">
                <Text type="h500" alignment="center"><FormattedMessage id={"sal_color_audit"}/></Text>
            </Box>}
            <Row spacingInset="small" widths="fill">
                <Box spacingInset="none none none small" type="outline">
                    <Text type="b500" alignment="left">{packageId}</Text>
                    <Text type="b200" alignment="left"><FormattedMessage id={"package_id"}/></Text>
                </Box>
            </Row>
            {!mismatch && <div>
                <Row widths="fill" spacingInset="small none none none">
                    <Text type="h300" alignment="center"><FormattedMessage id="Select SAL Color seen on Package"/></Text>
                </Row>
                <Row spacing="medium" spacingInset="small xlarge large xlarge" alignmentHorizontal={"center"}>
                    <Select value={selectedValue} onChange={setSelectedValue} placeholder="Select SAL Color" width={300}>
                        {Object.entries(SAL_COLOR_MAP).map(([hexCode, colorName]) => (
                            <SelectOption value={hexCode} label={colorName}>
                                {() => (
                                    <div  style={{display: 'flex', justifyContent: 'space-between'}}>
                                        <Text type="h100"><FormattedMessage id={colorName}/></Text>
                                        <Box backgroundColor={hexCode} spacingInset="medium large small small" type={"outline"}/>
                                    </div>
                                )}
                            </SelectOption>))
                        })
                    </Select>
                </Row>
            </div>}

            {!mismatch && selectedValue && <div>
                <Row spacing="medium" spacingInset="none large large large" alignmentHorizontal={"center"}>
                    <Box backgroundColor={selectedValue} spacingInset="xlarge" type={"outline"} width="66%"></Box>
                </Row>
                <Row width="100%" widths={["grid-10", "grid-10"]} wrap="down" alignmentHorizontal={"center"}>
                    <Button type="secondary" onClick={confirmSalColor}><FormattedMessage id="Confirm"/></Button>
                </Row>
            </div>}
        </Column>
    )
}
export default SalColorAudit;