import {callAPI} from "../network/base/NetworkInterface";
import {HTTP_METHOD} from "../constants/Constants";
import {Logger} from "@amzn/dolphin-web-framework";

const NodeNinjaServiceEndpoint = "/nns/";

const ENDPOINTS = {
    getShipmentsByScannableIdsV2: "getShipmentsByScannableIdsV2",
    getCategoriesForNodeId: "getCategoriesForNodeId",
    unassignRoute: "updateExceptionRoutesV2",
    scrubPackage: "scrubPackageV2",
    getStationList: "getStationList",
    getFeatureValue: "getFeatureValue",
    getPackageLabel: "getLabelByIdV2",
    getPackageAttribute: "getPackageAttribute"
}

export const NNSClient = {
    getShipmentsByScannableIdsV2: async (args) => {
        Logger.log.info("Request: getShipmentsByScannableIdsV2, Args: ", args);
        return await callAPI({
            method: HTTP_METHOD.POST,
            endpoint: getEndPoint(ENDPOINTS.getShipmentsByScannableIdsV2),
            data: args
        });
    },

    getCategoriesForNodeId: async () => {
        Logger.log.info("Request: getCategoriesForNodeIdV2");
        return await callAPI({
            method: HTTP_METHOD.POST,
            endpoint: getEndPoint(ENDPOINTS.getCategoriesForNodeId)
        });
    },

    unassignRoute: async (args) => {
        Logger.log.info("Request: unassignRouteV2, Args: ", args);
        return await callAPI({
            method: HTTP_METHOD.POST,
            endpoint: getEndPoint(ENDPOINTS.unassignRoute),
            data: args
        })
    },

    scrubPackage: async (args) => {
        Logger.log.info("Request: scrubPackageV2, Args: ", args);
        return await callAPI({
            method: HTTP_METHOD.POST,
            endpoint:  getEndPoint(ENDPOINTS.scrubPackage),
            data: args
        });
    },

    getStationList: async () => {
        Logger.log.info("Request: getStationList");
        return await callAPI({
            method: HTTP_METHOD.POST,
            endpoint: getEndPoint(ENDPOINTS.getStationList),
        })
    },

    getFeatureValue: async (args) => {
        Logger.log.info("Request: getFeatureValue, Args: ", args);
        return await callAPI({
            method: HTTP_METHOD.POST,
            endpoint: getEndPoint(ENDPOINTS.getFeatureValue),
            data: args
        });
    },

    getPackageLabel: async (args) => {
        Logger.log.info("Request: getPackageLabel, Args: ", args);
        return await callAPI({
            method: HTTP_METHOD.POST,
            endpoint: getEndPoint(ENDPOINTS.getPackageLabel),
            data: args
        })
    },

    getPackageAttribute: async (args) => {
        Logger.log.info("Request: getPackageAttribute, Args: ", args);
        return await callAPI({
            method: HTTP_METHOD.POST,
            endpoint: getEndPoint(ENDPOINTS.getPackageAttribute),
            data: args
        })
    }
}

const getEndPoint = (serviceEndpoint) => {
    return NodeNinjaServiceEndpoint + serviceEndpoint
}