import {APIEvents} from "../../../constants/AnalyticsEventKeys";
import {MobileAnalyticsHelper} from "@amzn/dolphin-web-framework";
import {getNNSClient} from "../../base/ClientInterface";

export function unassignRouteV2(data, dispatcher, nextAction, errorAction) {
    let startTime = Date.now();
    const routeCodeData = data.split("=");
    let routeCodeValue = routeCodeData[1];
    let structuredData = {
        routeCode: routeCodeValue
    };
    getNNSClient().unassignRoute(structuredData)
        .then(({success, data})=>{
            if(success && data.updateRouteSuccess) {
                dispatcher({
                    type: nextAction,
                    data: data,
                    dispatcher: dispatcher
                });
                MobileAnalyticsHelper.executeAPIAnalytics(APIEvents.UnassignRoute, startTime);
            } else {
                dispatcher({
                    type: errorAction
                });
                MobileAnalyticsHelper.executeAPIAnalytics(APIEvents.UnassignRoute, startTime, true);
            }
        });
}