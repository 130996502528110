
export const LOCATION_STATE_KEYS = {
    SELECTED_QUEUE_DETAILS: "SELECTED_QUEUE_DETAILS",
    QUEUE_TO_CATEGORIES: "QUEUE_TO_CATEGORIES",
    EXCEPTION_QUEUE_TO_COUNT: "EXCEPTION_QUEUE_TO_COUNT",
    EXCEPTION_SHIPMENT_SUMMARY_REPORT: "EXCEPTION_SHIPMENT_SUMMARY_REPORT",
    OPEN_EXCEPTION_SHIPMENT_WITH_DETAILS: "OPEN_EXCEPTION_SHIPMENT_WITH_DETAILS",
    LEFT_TO_FIX_FILTER: "LEFT_TO_FIX_FILTER",
    EXCEPTION_SHIPMENTS_WITH_DETAILS: "EXCEPTION_SHIPMENTS_WITH_DETAILS",
    SDC_VIEW_HEADING_KEY: "SDC_VIEW_HEADING_KEY",
    DRIVER_ACTION_TRANSPORTER: "DRIVER_ACTION_TRANSPORTER",
    ACTION_LIST: "ACTION_LIST",
    NEXT_SCREEN_AFTER_SUCCESS: "NEXT_SCREEN_AFTER_SUCCESS",
    ADDITIONAL_PROPERTIES: "ADDITIONAL_PROPERTIES",
    DRIVER_ENTITY_STATE: "DRIVER_ENTITY_STATE"

}

export function saveDriverActionTransporter(transporter){
    localStorage.setItem(LOCATION_STATE_KEYS.DRIVER_ACTION_TRANSPORTER, JSON.stringify(transporter))
}

export function getDriverActionTransporter(){
    return JSON.parse(localStorage.getItem(LOCATION_STATE_KEYS.DRIVER_ACTION_TRANSPORTER));
}

export function saveDriverEntityState(state) {
    localStorage.setItem(LOCATION_STATE_KEYS.DRIVER_ENTITY_STATE, JSON.stringify(state));
}

export function getDriverEntityState() {
    return JSON.parse(localStorage.getItem(LOCATION_STATE_KEYS.DRIVER_ENTITY_STATE));
}

export function saveExceptionShipmentsWithDetails(exceptionShipmentsWithDetails) {
    localStorage.setItem(LOCATION_STATE_KEYS.EXCEPTION_SHIPMENTS_WITH_DETAILS, JSON.stringify(exceptionShipmentsWithDetails))
}

export function getExceptionShipmentsWithDetails(){
    return JSON.parse(localStorage.getItem(LOCATION_STATE_KEYS.EXCEPTION_SHIPMENTS_WITH_DETAILS));
}

export function saveSdViewHeadingKey(sdViewHeadingKey) {
    localStorage.setItem(LOCATION_STATE_KEYS.SDC_VIEW_HEADING_KEY, JSON.stringify(sdViewHeadingKey))
}

export function getSdViewHeadingKey(){
    return JSON.parse(localStorage.getItem(LOCATION_STATE_KEYS.SDC_VIEW_HEADING_KEY));
}

export function saveSelectedQueueDetails(selectedQueueDetails) {
    localStorage.setItem(LOCATION_STATE_KEYS.SELECTED_QUEUE_DETAILS, JSON.stringify(selectedQueueDetails));
}

export function getSelectedQueueDetails() {
    return JSON.parse(localStorage.getItem(LOCATION_STATE_KEYS.SELECTED_QUEUE_DETAILS));
}

export function saveQueueToCategories(queueToCategories) {
    localStorage.setItem(LOCATION_STATE_KEYS.QUEUE_TO_CATEGORIES, JSON.stringify(queueToCategories));
}

export function getQueueToCategories() {
    return JSON.parse(localStorage.getItem(LOCATION_STATE_KEYS.QUEUE_TO_CATEGORIES));
}

export function saveExceptionQueueToCount(exceptionQueueToCount) {
    localStorage.setItem(LOCATION_STATE_KEYS.EXCEPTION_QUEUE_TO_COUNT, exceptionQueueToCount);
}

export function getExceptionQueueToCount() {
    return (LOCATION_STATE_KEYS.EXCEPTION_QUEUE_TO_COUNT);
}

export function saveExceptionShipmentSummaryReport(exceptionShipmentSummaryReport) {
    localStorage.setItem(LOCATION_STATE_KEYS.EXCEPTION_SHIPMENT_SUMMARY_REPORT, JSON.stringify(exceptionShipmentSummaryReport));
}

export function getExceptionShipmentSummaryReport() {
    return JSON.parse(localStorage.getItem(LOCATION_STATE_KEYS.EXCEPTION_SHIPMENT_SUMMARY_REPORT));
}

export function saveOpenExceptionShipmentsWithDetails(openExceptionShipmentsWithDetails) {
    localStorage.setItem(LOCATION_STATE_KEYS.OPEN_EXCEPTION_SHIPMENT_WITH_DETAILS, JSON.stringify(openExceptionShipmentsWithDetails));
}

export function getOpenExceptionShipmentsWithDetails() {
    return JSON.parse(localStorage.getItem(LOCATION_STATE_KEYS.OPEN_EXCEPTION_SHIPMENT_WITH_DETAILS));
}

export function saveLeftToFixFilters(leftToFixFilters) {
    localStorage.setItem(LOCATION_STATE_KEYS.LEFT_TO_FIX_FILTER, JSON.stringify(leftToFixFilters));
}

export function getLeftToFixFilters() {
    return JSON.parse(localStorage.getItem(LOCATION_STATE_KEYS.LEFT_TO_FIX_FILTER));
}

export function saveActionList(actionList) {
    localStorage.setItem(LOCATION_STATE_KEYS.ACTION_LIST, JSON.stringify(actionList));
}

export function getActionListFromDataStore() {
    return JSON.parse(localStorage.getItem(LOCATION_STATE_KEYS.ACTION_LIST));
}

export function saveNextScreenAfterSuccess(nextScreen) {
    localStorage.setItem(LOCATION_STATE_KEYS.NEXT_SCREEN_AFTER_SUCCESS, JSON.stringify(nextScreen));
}

export function getNextScreenAfterSuccess() {
    try {
        return JSON.parse(localStorage.getItem(LOCATION_STATE_KEYS.NEXT_SCREEN_AFTER_SUCCESS));
    }catch (s) {
        return undefined;
    }
}

export function saveAdditionalProperties(additionalProperties) {
    localStorage.setItem(LOCATION_STATE_KEYS.ADDITIONAL_PROPERTIES, JSON.stringify(additionalProperties));
}

export function getAdditionalProperties() {
    try {
        return JSON.parse(localStorage.getItem(LOCATION_STATE_KEYS.ADDITIONAL_PROPERTIES));
    }catch (s) {
        return undefined;
    }
}

