import React from 'react';
import {
    PARTIAL_ACTION_SUCCESS_STRING_KEY,
    PLURAL_ACTION_SUCCESS_STRING_KEY,
    SINGULAR_ACTION_SUCCESS_STRING_KEY,
    RTO_SUCCESS_SCREEN_TEXT_MESSAGE1_STRING_KEY,
    RTO_SUCCESS_SCREEN_TEXT_MESSAGE2_STRING_KEY,
    RTO_SUCCESS_SCREEN_TEXT_MESSAGE3_STRING_KEY,

    RTO_PRINT_SUCCESS_SCREEN_TEXT_MESSAGE1_STRING_KEY,
    RTO_PRINT_SUCCESS_SCREEN_TEXT_MESSAGE2_STRING_KEY,
    RTO_PRINT_SUCCESS_SCREEN_TEXT_MESSAGE3_STRING_KEY,
    RTO_PRINT_SUCCESS_SCREEN_TEXT_MESSAGE4_STRING_KEY,

    NETWORK_MISSORT_SUCCESS_SCREEN_HEADING_STRING_KEY,
    NETWORK_MISSORT_SUCCESS_SCREEN_GUIDANCE_MESSAGE_STRING_KEY,
    NETWORK_MISSORT_SUCCESS_SCREEN_GUIDANCE_MESSAGE_STRING_KEY_FOR_LABEL_PRINT,
    NETWORK_MISSORT_PRINT_SUCCESS_SCREEN_GUIDANCE_MESSAGE_STRING_KEY

} from "../constants/SuccessStringConstants";
import translate from "../handler/TranslationHandler";
import {RETURN_TO_SC, MiddleMilePlanDirection} from "../constants/Constants";

export const getSecondaryTitle = (successCount, totalCount, action, trackingIds, problemCategory, metaData) => {
    let key = "";
    if(totalCount === successCount) {
        var scannableId = (trackingIds[0])?trackingIds[0].substr(trackingIds[0].length-4):"";
        if(successCount === 1) {
            key = SINGULAR_ACTION_SUCCESS_STRING_KEY[action]
            return translate(key, {...metaData, trackingId: <b>{scannableId}</b>,
                problemCategory: problemCategory})
        } else {
            key = PLURAL_ACTION_SUCCESS_STRING_KEY[action]
            return translate(key, {...metaData, count: successCount, problemCategory: problemCategory})
        }
    } else {
        key = PARTIAL_ACTION_SUCCESS_STRING_KEY[action]
        return translate(key, {...metaData, totalCount: totalCount, successCount: successCount, problemCategory: problemCategory})
    }
}

export const getRTOSuccessScreenTextMessage1 = (action, trackingIds, problemCategory, metaData) => {
    var key = RTO_SUCCESS_SCREEN_TEXT_MESSAGE1_STRING_KEY[action];
    var scannableId = (trackingIds[0])?trackingIds[0].substr(trackingIds[0].length-4):"";
    return translate(key, {...metaData, trackingId: <b>{scannableId}</b>, problemCategory: problemCategory})
}
export const getRTOSuccessScreenTextMessage2 = (action, trackingIds, problemCategory, metaData) => {
    var key = RTO_SUCCESS_SCREEN_TEXT_MESSAGE2_STRING_KEY[action];
    var scannableId = (trackingIds[0])?trackingIds[0].substr(trackingIds[0].length-4):"";
    return translate(key, {...metaData, trackingId: <b>{scannableId}</b>, problemCategory: problemCategory})
}

export const getRTOSuccessScreenTextMessage3 = (action, trackingIds, problemCategory, metaData) => {
    var key = RTO_SUCCESS_SCREEN_TEXT_MESSAGE3_STRING_KEY[action];
    var scannableId = (trackingIds[0])?trackingIds[0].substr(trackingIds[0].length-4):"";
    return translate(key, {...metaData, trackingId: <b>{scannableId}</b>, problemCategory: problemCategory})
}

export const getRTOPrintSuccessScreenTextMessage1 = (action, trackingIds, problemCategory, metaData) => {
    var key = RTO_PRINT_SUCCESS_SCREEN_TEXT_MESSAGE1_STRING_KEY[action];
    var scannableId = (trackingIds[0])?trackingIds[0].substr(trackingIds[0].length-4):"";
    return translate(key, {...metaData, trackingId: <b>{scannableId}</b>, problemCategory: problemCategory})
}

export const getRTOPrintSuccessScreenTextMessage2 = (action, trackingIds, problemCategory, metaData) => {
    var key = RTO_PRINT_SUCCESS_SCREEN_TEXT_MESSAGE2_STRING_KEY[action];
    var scannableId = (trackingIds[0])?trackingIds[0].substr(trackingIds[0].length-4):"";
    return translate(key, {...metaData, trackingId: <b>{scannableId}</b>, problemCategory: problemCategory})
}

export const getRTOPrintSuccessScreenTextMessage3 = (action, trackingIds, problemCategory, metaData) => {
    var key = RTO_PRINT_SUCCESS_SCREEN_TEXT_MESSAGE3_STRING_KEY[action];
    var scannableId = (trackingIds[0])?trackingIds[0].substr(trackingIds[0].length-4):"";
    return translate(key, {...metaData, trackingId: <b>{scannableId}</b>, problemCategory: problemCategory})
}

export const getRTOPrintSuccessScreenTextMessage4 = (action, trackingIds, problemCategory, metaData) => {
    var key = RTO_PRINT_SUCCESS_SCREEN_TEXT_MESSAGE4_STRING_KEY[action];
    var scannableId = (trackingIds[0])?trackingIds[0].substr(trackingIds[0].length-4):"";
    return translate(key, {...metaData, trackingId: <b>{scannableId}</b>, problemCategory: problemCategory})
}

export const getNetworkMissortHeading = (planDirection, isPrintLabelRequired) => {
    if (isPrintLabelRequired)
        return NETWORK_MISSORT_SUCCESS_SCREEN_HEADING_STRING_KEY.PRINT_NEW_LABEL
    else
        return NETWORK_MISSORT_SUCCESS_SCREEN_HEADING_STRING_KEY[planDirection]
}

export const getNetworkMissortGuidanceMessage = (planDirection, isPrintLabelRequired) => {
    if (RETURN_TO_SC === planDirection && isPrintLabelRequired)
        return NETWORK_MISSORT_SUCCESS_SCREEN_GUIDANCE_MESSAGE_STRING_KEY_FOR_LABEL_PRINT[planDirection]
    else
        return NETWORK_MISSORT_SUCCESS_SCREEN_GUIDANCE_MESSAGE_STRING_KEY[planDirection]
}

export const getNetworkMissortPrintSuccessScreenGuidanceMessage = (planDirection) => {
    if (MiddleMilePlanDirection.includes(planDirection))
        return NETWORK_MISSORT_PRINT_SUCCESS_SCREEN_GUIDANCE_MESSAGE_STRING_KEY.RETURN_TO_MIDDLE_MILE
    else
        return NETWORK_MISSORT_SUCCESS_SCREEN_GUIDANCE_MESSAGE_STRING_KEY[planDirection]
}
