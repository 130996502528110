import React, {useContext, useEffect } from 'react';
import {withRouter, useLocation} from 'react-router-dom';
import helpTokens from "@amzn/meridian-tokens/base/icon/help";
import {FormattedMessage, injectIntl} from "react-intl";
import {TopNavigationView} from "@amzn/dolphin-uiweb-framework";
import PropTypes from 'prop-types';
import {ROUTE_PATH} from "../../router/routing";
import {BACKGROUND_DEFAULT} from "../../constants/Constants";
import {NativeMeshInteractor} from "@amzn/dolphin-web-framework";
import {getDisplayName} from "../../handler/TranslationHandler";
import {AppContext} from "../backPressedView/AppContext";
import {
    clearBackPressedEventListener,
    setBackPressedEventListener
} from "../backPressedView/BackButton"
import {BackPressedView} from "@amzn/dolphin-uiweb-framework";
import Column from "@amzn/meridian/column";
import {getProblemAction} from "../../dataStore/PSExecutionDataStore";
import {NETWORK_MISSORT_REPLAN} from "../../constants/Constants";
import {
    clearPSModuleData
} from "../../dataStore/PSExecutionDataStore";


var primaryOnClickListener = null;
export const setPrimaryOnClickListener = (func) => {
    primaryOnClickListener = func;
}
export const resetPrimaryOnClickListener = () => {
    primaryOnClickListener = null;
}


const TopNavigation = ({history, intl, title, background}) => {
    var location = useLocation();

    const {state: {
        onBackPressed,
        backOptions,
        helpOptions
    } = {}, appActions} = useContext(AppContext);

    const isNetworkMissortAction = (getProblemAction() === NETWORK_MISSORT_REPLAN);

    const handleBackButtonPress = () => {
        if(location.pathname === ROUTE_PATH.HOME) {
            clearPSModuleData();
            NativeMeshInteractor.exitModule();
        } else {
            history.goBack();
        }
    };

    const handleBackPressPrimaryButtonClick= () => {
        appActions.closeBackDrop();
        primaryOnClickListener && primaryOnClickListener();
    };

    useEffect(() => {
        setBackPressedEventListener(handleBackButtonPress);
        return () => {
            clearBackPressedEventListener();
            resetPrimaryOnClickListener();
        }
    }, [onBackPressed, backOptions]);

    const getOverlayText = () => {
        return isNetworkMissortAction ? "network_missort_backoverlay_text" : "rto_backoverlay_text";
    }

    const getSecondaryButtonAction = () => {
        return isNetworkMissortAction ? history.push(ROUTE_PATH.HOME) : appActions.closeBackDrop();
    }

    return (
        <Column>
            <TopNavigationView title={intl.formatMessage({id: title})} backgroundType={background || BACKGROUND_DEFAULT}
                           optionsMenu={[{
                               icon: helpTokens,
                               onClick: () => {
                                   history.push({pathname: ROUTE_PATH.HELP_CENTER, state: {helpOptions}});
                               },
                               label: getDisplayName(intl, "help_center")
                           }]}
                           onMenuButtonClicked={handleBackButtonPress}/>
            {
                backOptions.open &&
                <BackPressedView
                    onClose={() => {appActions.closeBackDrop();}}
                    title=<FormattedMessage id="rto_backoverlay_title"/>
                    description=<FormattedMessage id={getOverlayText()}/>
                    primaryButtonTitle=<FormattedMessage id="rto_backoverlay_primary_button"/>
                    primaryButtonOnClick={handleBackPressPrimaryButtonClick}
                    secondaryButtonTitle=<FormattedMessage id="rto_backoverlay_secondary_button"/>
                    secondaryButtonOnClick={() => {getSecondaryButtonAction();}}/>
            }

        </Column>
    )
};

TopNavigation.propTypes = {
    intl: PropTypes.object,
    title: PropTypes.string.isRequired,
    history: PropTypes.object,
    background: PropTypes.string
};
export default withRouter(injectIntl(TopNavigation))